import { useQuery } from '@tanstack/react-query';
import denseairClient from './denseairClient';

const useDashBoardData = (dashboardId: string) => {
  return useQuery(['dashboardDetails', dashboardId], () =>
    denseairClient.metadata.getDashboard({ dashboardId })
  );
};

export default useDashBoardData;
