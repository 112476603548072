import { useQuery } from '@tanstack/react-query';
import dwAxiosClient from './densewareAxiosClient';

export type MccMnc = `${string}-${string}`;

type DWProvider = {
  brandName: string;
  isMvno: boolean;
  name: string;
  providerId: number;
  mccMnc: MccMnc[];
  mcc: string[];
  mnc: string[];
};

type DWContext = {
  countryCode: string;
  name: string;
  providers: DWProvider[];
};

export type ContextResponse = DWContext[];

const getContexts = async () => {
  const { data, status } = await dwAxiosClient.get<ContextResponse>(
    '/Context/GetAllowed'
  );

  if (status === 200) {
    return data;
  }

  throw new Error('Error fetching contexts');
};

const useContextQueryClient = () => {
  return useQuery<ContextResponse, Error>(['contexts'], getContexts, {
    staleTime: 10 * 60 * 60 * 1000,
    retry: false,
  });
};

const usePlmnQueryClient = () => {
  const contextQuery = useContextQueryClient();

  return useQuery({
    queryKey: ['plmns'],
    queryFn: () => {
      if (contextQuery.isSuccess) {
        return contextQuery.data.reduce(
          (
            acc: { allPlmns: string[]; allMccs: string[]; allMncs: string[] },
            country
          ) => {
            country.providers.forEach((provider) => {
              acc.allPlmns = acc.allPlmns.concat(provider.mccMnc);
              acc.allMccs = acc.allMccs.concat(provider.mcc);
              acc.allMncs = acc.allMncs.concat(provider.mnc);
            });

            return acc;
          },
          { allPlmns: [], allMccs: [], allMncs: [] }
        );
      }

      return { allPlmns: [], allMccs: [], allMncs: [] };
    },
    enabled: contextQuery.isSuccess,
  });
};

const usePlmnByProviderQueryClient = (options: {
  countryCode: string | null;
  providerName: string | null;
}) => {
  const contextData = useContextQueryClient();

  return useQuery({
    queryKey: ['plmns', options.countryCode, options.providerName],
    queryFn: () => {
      if (contextData.isSuccess) {
        const country = contextData.data.find((countries) => {
          return countries.countryCode === options.countryCode;
        });

        if (country) {
          const provider = country.providers.find((providers) => {
            return providers.name === options.providerName;
          });

          if (provider) {
            return {
              plmns: provider.mccMnc,
              mcc: provider.mcc,
              mnc: provider.mnc,
            };
          }
        }
      }

      return { plmns: [], mcc: [], mnc: [] };
    },
    enabled: contextData.isSuccess,
  });
};

export { usePlmnQueryClient, usePlmnByProviderQueryClient };
export default useContextQueryClient;
