import { Grid } from '@chakra-ui/react';
import DashboardList from '../components/DashList/DashList';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Title from '../components/Title/Title';

const HomePage = () => {
  return (
    <Grid
      minH="100vh"
      maxHeight="100vh"
      templateRows="max-content 1fr max-content"
    >
      <Header />
      <Grid templateRows="min-content 1fr" overflow="hidden" padding={'0 20px'}>
        <Title />
        <DashboardList />
      </Grid>
      <Footer
        aboutUsHref={import.meta.env.VITE_APP_ABOUT_US}
        version={import.meta.env.VITE_APP_VERSION}
      />
    </Grid>
  );
};

export default HomePage;
