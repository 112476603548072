import { FC } from 'react';
import { useAppSelector } from '../../hooks/useAppDispatch';
import { useGetMeasuresListClient } from '../../services/measureClient';
import { useGetThresholdLists } from '../../services/userProfileClient';
import { GetMeasuresListResponse } from '../../utils/denseware-client-js/client/types';
import EditMiniModal from './EditMiniModal';

type MiniModalProps = {
  measureIds: string[] | undefined;
};

const EditMiniModalWrapper: FC<MiniModalProps> = (props) => {
  const { measureIds } = props;
  const {
    data: allExistingThresholdLists,
    refetch: refetchList,
    status: thresholdListsStatus,
  } = useGetThresholdLists();

  const { data: measuresList, status: measuresListStatus } =
    useGetMeasuresListClient();
  const selectedId = useAppSelector(
    (state) => state.personalizationSetting.selectedListId
  );

  if (
    measureIds &&
    thresholdListsStatus === 'success' &&
    measuresListStatus === 'success'
  ) {
    // out of all the thresholds lists,
    // find the one that is for the currently selected threshold list
    // .e.g. caesers palace
    const selectedListData = allExistingThresholdLists.find((item) => {
      if (item.userProfileId === selectedId) {
        return item;
      }
    });

    const applicableMeasures = Object.entries(measuresList.data.data).reduce(
      (acc: GetMeasuresListResponse['data'], [measureId, measureDetail]) => {
        if (measureIds?.includes(measureId)) {
          acc[measureId] = measureDetail;
        }

        return acc;
      },
      {}
    );

    if (selectedListData) {
      return (
        <EditMiniModal
          measureIds={measureIds}
          measuresList={applicableMeasures}
          data={selectedListData}
          refetch={refetchList}
        />
      );
    }
  }

  return null;
};

export default EditMiniModalWrapper;
