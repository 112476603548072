import { Box, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppDispatch';
import {
  PersonalizationModalState,
  ThresholdTab,
} from '../../store/modalTabSlice';
import { getInitials } from '../../utils/AuthProvider';
import Personalization from './Personalization';

const Profile: FC = () => {
  const isModalOpen = useAppSelector(
    (state) => state.modalTabSettings.isPersonalizationModalOpen
  );
  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        display={'grid'}
        background={'white'}
        width={'40px'}
        height={'40px'}
        borderRadius={'25px'}
        alignItems={'center'}
        alignContent={'middle'}
        justifyContent={'center'}
        justifyItems={'center'}
        _hover={{ bg: 'lightgrey' }}
        onClick={() => {
          dispatch(PersonalizationModalState(!isModalOpen));
          dispatch(ThresholdTab('Threshold'));
        }}
      >
        <Box id="profile-name" color={'primary'} fontWeight={'semibold'}>
          {getInitials()}
        </Box>
      </Button>
      <Personalization currentState={isModalOpen} />
    </>
  );
};

export default Profile;
