import {
  Button,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  useGetThresholdLists,
  UserProfileId,
} from '../../services/userProfileClient';
import {
  PersonalizationModalState,
  ThresholdTab,
} from '../../store/modalTabSlice';
import { SelectedListValue } from '../../store/personalizationSlice';

const ThresholdsSelection: FC = () => {
  const { data, status } = useGetThresholdLists();
  const dispatch = useDispatch();

  type Option = {
    label: string;
    value: UserProfileId;
  };

  const options: Option[] = [];

  if (status === 'success') {
    data.map((item) => {
      const thresholdData = JSON.parse(item.payload);
      const sectionName = item.sectionName;
      if (sectionName === 'Threshold') {
        const name = thresholdData.listName;
        const option = {
          label: name,
          value: item.userProfileId,
        };
        options.push(option);
      }
    });
  }

  const handleOnClick = (selectedListId: Option | null | undefined) => {
    if (selectedListId !== undefined && selectedListId !== null) {
      dispatch(SelectedListValue(selectedListId.value));
    } else {
      dispatch(SelectedListValue(null));
    }
  };
  return (
    <Popover>
      <PopoverTrigger>
        <Button borderRadius={10}>Thresholds</Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody p={'20px'}>
            <Heading size={'sm'} mb={'10px'} fontWeight={'light'}>
              Please select the Threshold
            </Heading>
            <>
              {options.length > 0 ? (
                <Select
                  options={options}
                  onChange={(e) => {
                    handleOnClick(e);
                  }}
                  isClearable
                />
              ) : null}
              <Button
                onClick={() => {
                  dispatch(PersonalizationModalState(true));
                  dispatch(ThresholdTab('Threshold'));
                }}
                mt={'10px'}
              >
                Add Item
              </Button>
            </>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ThresholdsSelection;
