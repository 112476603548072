import parseISO from 'date-fns/parseISO';

type RawPerSeries = {
  [seriesName: string]: Array<
    | {
        DateAndTimeStart: string;
        DateAndTimeEnd: string;
        value: number;
      }
    | {
        DateAndTimeStart: string;
        DateAndTimeEnd: string;
        value: null;
      }
  >;
};

type ChartData = { x: number; y: number }[][];

type TransformResult = {
  data: ChartData;
  ids: string[];
};

const perSeriesResponseToChartData = (input: RawPerSeries): TransformResult => {
  const nodeAndData = Object.entries(input);

  const ids: string[] = [];

  const data = nodeAndData.map((entry) => {
    const [nodeId, nodeData] = entry;

    ids.push(nodeId);
    return nodeData
      .filter(
        (
          d
        ): d is {
          DateAndTimeStart: string;
          DateAndTimeEnd: string;
          value: number;
        } => d.value !== null
      )
      .map((d) => {
        return {
          x: parseISO(d.DateAndTimeStart).getTime(),
          y: d.value,
        };
      })
      .sort((a, b) => {
        return a.x - b.x;
      });
  });

  return { ids, data };
};

export { perSeriesResponseToChartData };
