import { useQuery } from '@tanstack/react-query';
import {
  GetNodesResponse,
  TechnologyType,
} from '../utils/denseware-client-js/client/types';
import denseairClient from './denseairClient';

type GetNodesByTechnologyClient = {
  plmns: string[] | undefined;
  technologyType: TechnologyType;
};

type GetNodesOptions = {
  plmns: string[] | undefined;
  technologyTypes?: TechnologyType[] | undefined;
};

export const useNodesByTechnologyClient = (
  options: GetNodesByTechnologyClient
) => {
  const { plmns, technologyType } = options;

  return useQuery({
    queryKey: ['nodes', plmns, technologyType],
    queryFn: async ({ signal }) => {
      if (plmns === undefined || plmns.length === 0) {
        return {};
      }

      const request = await denseairClient.nodes.getNodes({
        params: { plmn: plmns, technologyType: [technologyType] },
        axiosOptions: { signal },
      });

      const dataMap = new Map();
      if (request.status === 200) {
        Object.entries(request.data.data).forEach(([k, v]) => {
          dataMap.set(k, v);
        });
      }

      const data: GetNodesResponse['data'] = Object.fromEntries(dataMap);

      return data;
    },
    staleTime: 1000 * 60 * 5,
  });
};

export const useNodesClient = (options: GetNodesOptions) => {
  const { plmns, technologyTypes = ['4G', '5G'] } = options;

  return useQuery({
    queryKey: ['nodes', plmns],
    queryFn: async ({ signal }) => {
      if (plmns === undefined || plmns.length === 0) {
        return {};
      }

      const request = await denseairClient.nodes.getNodes({
        params: { plmn: plmns, technologyType: technologyTypes },
        axiosOptions: { signal },
      });

      const dataMap = new Map();
      if (request.status === 200) {
        Object.entries(request.data.data).forEach(([k, v]) => {
          dataMap.set(k, v);
        });
      }

      const data: GetNodesResponse['data'] = Object.fromEntries(dataMap);

      return data;
    },
    staleTime: 1000 * 60 * 5,
  });
};
