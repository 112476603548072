import { Box, HStack, IconButton } from '@chakra-ui/react';
import type { ChartData } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { useUniqueListState } from '../../hooks/useListState';
import { categoryColors } from '../../utils/seriesColors';
import calculateTrends from '../../utils/trendLineCalculations';
import Legend from './Legend';

export type ThresholdLine = {
  value: number;
  color: string;
  style: 'solid' | 'dash-lg' | 'dash-sm';
};

export type ChartPoint = {
  x: number;
  y: number;
};

type ArrayOfPoints = ChartPoint[];

export type ListOfLines = {
  data: ArrayOfPoints;
  additionalData: Array<{ data: ArrayOfPoints; color: string; label: string }>;
}[];

type LineChartProps = {
  chartData: ListOfLines;
  title?: string | null | undefined;
  seriesLabels?: string[] | undefined;
  xLabel?: undefined | null | string;
  y0Label?: undefined | null | string;
  min?: number | undefined;
  max?: number | undefined;
  spanGapsSize: number | undefined;
  thresholdValue?: ThresholdLine[];
  showTrendLine: boolean;
  // showRollingAverage: boolean;
};

const LineChart: FC<LineChartProps> = (props) => {
  const {
    chartData,
    seriesLabels,
    xLabel,
    y0Label,
    min,
    max,
    spanGapsSize,
    thresholdValue,
    showTrendLine,
    // showRollingAverage,
  } = props;

  const {
    state: hiddenSeries,
    toggleItem: toggleHiddenSeries,
    setList: setHiddenSeries,
  } = useUniqueListState<string>([]);

  const [searchParams] = useSearchParams();
  const selectedTimeZone = searchParams.get('timezone');

  const mainData: ChartData<'line', Array<ChartPoint>>['datasets'] =
    chartData.map((item, i) => {
      const seriesLabel = seriesLabels ? seriesLabels[i] : null;
      const isHidden =
        seriesLabel && hiddenSeries.includes(seriesLabel) ? true : false;

      return {
        data: item.data.filter((p) => p.y !== null),
        spanGaps: spanGapsSize,
        pointBackgroundColor: isHidden ? 'transparent' : categoryColors[i],
        backgroundColor: isHidden ? 'transparent' : categoryColors[i],
        borderColor: isHidden ? 'transparent' : categoryColors[i],
        ...(seriesLabels && { label: seriesLabels[i] }),
        borderWidth: 2,
        pointRadius: 2,
        fill: false,
        label: seriesLabel ? seriesLabel : 'Value',
      };
    });

  const additionalData: ChartData<'line', Array<ChartPoint>>['datasets'] =
    chartData.flatMap((item, i) => {
      return item.additionalData.map((data) => {
        const seriesLabel = seriesLabels ? seriesLabels[i] : null;
        const isHidden =
          seriesLabel && hiddenSeries.includes(seriesLabel) ? true : false;

        return {
          data: data.data.filter((p) => p.y !== null),
          spanGaps: spanGapsSize,
          pointBackgroundColor: isHidden ? 'transparent' : data.color,
          backgroundColor: isHidden ? 'transparent' : categoryColors[i],
          borderColor: isHidden ? 'transparent' : categoryColors[i],
          ...(seriesLabels && { label: seriesLabels[i] }),
          borderWidth: 1,
          pointRadius: 2,
          fill: false,
          label: data.label,
        };
      });
    });

  const lineData: ChartData<'line', Array<ChartPoint>> = {
    datasets: [...mainData, ...additionalData],
  };

  const TrendLineData = mainData
    .filter((item) => {
      if (
        item.pointBackgroundColor !== 'transparent' &&
        item.backgroundColor !== 'transparent' &&
        item.borderColor !== 'transparent'
      ) {
        return true;
      }
      return false;
    })
    .map((item) => {
      return item.data;
    });

  const trends2 = calculateTrends(showTrendLine ? TrendLineData : []);

  const annoOpts = thresholdValue
    ? thresholdValue.map((o, index) => {
        const annotationOption: AnnotationOptions = {
          id: `${index}`,
          type: 'line',
          value: o.value,
          borderDash:
            o.style === 'solid'
              ? undefined
              : o.style === 'dash-lg'
              ? [16, 8]
              : [6, 4],
          borderColor: `${o.color}`,
          scaleID: 'y',
          borderWidth: 1.3,
        };

        return annotationOption;
      })
    : [];

  return (
    <Box>
      <Line
        data={lineData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            annotation: {
              annotations: [...annoOpts, ...trends2],
            },
            tooltip: {
              mode: 'x',
              position: 'nearest',
              boxPadding: 8,
              padding: { left: 10, right: 10, top: 16, bottom: 8 },
              itemSort: (a, b) => {
                const aDate = a.parsed.x;
                const bDate = b.parsed.x;

                if (aDate === bDate && a.dataset.label && b.dataset.label) {
                  return a.dataset.label.localeCompare(b.dataset.label);
                }

                return aDate - bDate;
              },
              callbacks: {
                title: () => '',
                label: (context) => {
                  const originalLabel = context.dataset.label;
                  const xValue = context.parsed.x;
                  const luxDate = DateTime.fromMillis(xValue);
                  const formattedDate = luxDate
                    .setZone(selectedTimeZone ?? 'Etc/UTC')
                    .toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS);

                  return `${formattedDate} - ${originalLabel}: ${context.parsed.y.toLocaleString(
                    'en-US',
                    {
                      useGrouping: false,
                      maximumFractionDigits: 3,
                    }
                  )}`;
                },
              },
            },
          },

          clip: 10,
          scales: {
            x: {
              type: 'time',
              adapters: {
                date: {
                  zone: selectedTimeZone,
                },
              },
              time: {
                displayFormats: {
                  millisecond: 'HH:mm:ss',
                  second: 'HH:mm:ss',
                  minute: 'HH:mm:ss',
                  hour: 'dd MMM yyyy HH:mm:ss',
                  day: 'dd MMM yyyy',
                  week: 'dd MMM yyyy',
                  month: 'dd MMM yyyy',
                  quarter: 'dd MMM yyyy',
                  year: 'dd MMM yyyy',
                },
              },
              suggestedMin: min,
              suggestedMax: max,
              title: { ...(xLabel ? { display: true, text: xLabel } : false) },
            },
            y: {
              title: {
                ...(y0Label ? { display: true, text: y0Label } : false),
              },
            },
          },
        }}
      />
      {seriesLabels !== undefined ? (
        <Box mb="6" mt="2">
          <Legend
            items={seriesLabels}
            hiddenItems={hiddenSeries}
            onClickItem={(seriesLabel) => {
              toggleHiddenSeries(seriesLabel);
            }}
          />
          <HStack spacing="2" mt="3" justifyContent="center">
            <IconButton
              size="sm"
              aria-label="Hide all items"
              onClick={() => {
                setHiddenSeries(seriesLabels);
              }}
              icon={<HiEyeOff />}
              isDisabled={hiddenSeries.length === seriesLabels.length}
            />
            <IconButton
              size="sm"
              aria-label="Show all items"
              onClick={() => {
                setHiddenSeries([]);
              }}
              icon={<HiEye />}
              isDisabled={hiddenSeries.length === 0}
            />
          </HStack>
        </Box>
      ) : null}
    </Box>
  );
};

export default LineChart;
