import { IntervalEnum } from './denseware-client-js/client/types';

const ONE_MINUTE_MS = 60 * 1000;
const FIVE_MINUTE_MS = 5 * ONE_MINUTE_MS;
const TEN_MINUTE_MS = 10 * ONE_MINUTE_MS;
const FIFTEEN_MINUTE_MS = 15 * ONE_MINUTE_MS;
const THIRTY_MINUTE_MS = 30 * ONE_MINUTE_MS;

const ONE_HOUR_MS = 60 * 60 * 1000;
const SIX_HOUR_MS = 6 * ONE_HOUR_MS;
const TWELVE_HOUR_MS = 12 * ONE_HOUR_MS;

const ONE_DAY_MS = 24 * ONE_HOUR_MS;
const SEVEN_DAY_MS = ONE_DAY_MS * 7;
const FOURTEEN_DAY_MS = ONE_DAY_MS * 14;
const THIRTY_DAY_MS = ONE_DAY_MS * 30;
const ONE_YEAR_MS = ONE_DAY_MS * 365;

export const timeIntervalOptions: Array<{
  label: string;
  value: IntervalEnum;
  min: number;
  max: number;
}> = [
  { label: '5m', value: '300', min: FIVE_MINUTE_MS, max: ONE_DAY_MS },
  { label: '10m', value: '600', min: TEN_MINUTE_MS, max: ONE_DAY_MS },
  { label: '15m', value: '900', min: FIFTEEN_MINUTE_MS, max: ONE_DAY_MS },
  { label: '30m', value: '1800', min: THIRTY_MINUTE_MS, max: ONE_DAY_MS },
  { label: '1h', value: '3600', min: ONE_HOUR_MS, max: THIRTY_DAY_MS },
  { label: '6h', value: '21600', min: SIX_HOUR_MS, max: THIRTY_DAY_MS },
  { label: '12h', value: '43200', min: TWELVE_HOUR_MS, max: THIRTY_DAY_MS },
  { label: '1d', value: '86400', min: ONE_DAY_MS, max: ONE_YEAR_MS },
  { label: '7d', value: '604800', min: SEVEN_DAY_MS, max: ONE_YEAR_MS },
  { label: '14d', value: '1209600', min: FOURTEEN_DAY_MS, max: ONE_YEAR_MS },
  { label: '30d', value: '2592000', min: THIRTY_DAY_MS, max: Infinity },
];
