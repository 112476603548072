import { Button } from '@chakra-ui/react';
import saveAs from 'file-saver';
import { FC } from 'react';

export type ExportDataProps = {
  exportName: string;
  title?: string | undefined;
  chartData: { [heading: string]: number | string | null }[];
  extraRowName?: string;
  extraRowData?: string[];
};

const ExportData: FC<ExportDataProps> = (props) => {
  const { exportName, title, chartData, extraRowName, extraRowData } = props;
  if (chartData.length === 0) {
    return null;
  }
  const headerRowSet = chartData.reduce((acc: Set<string>, row) => {
    Object.keys(row).forEach((keyName) => {
      acc.add(keyName);
    });

    return acc;
  }, new Set() as Set<string>);

  const headerRow: string[] = Array.from(headerRowSet.values());

  const remainingRows = chartData.map((row) => {
    return headerRow.map((header) => `"${row[header] ?? ''}"`).join(',');
  });

  const allRows = [
    extraRowName ?? null,
    extraRowData ?? null,
    headerRow.map((text) => `"${text}"`).join(','),
    ...remainingRows,
  ].join('\n');

  const onExportLocal = async () => {
    const blob = new Blob([allRows], { type: 'text/csv' });
    saveAs(blob, `${title}.csv`);
  };

  return (
    <Button
      fontSize={'x-small'}
      fontWeight={'normal'}
      onClick={onExportLocal}
      size={'xs'}
      variant="unstyled"
    >
      {exportName}
    </Button>
  );
};

export default ExportData;
