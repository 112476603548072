import { Box, Grid, GridItem, Heading, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import useDashBoardData from '../../services/dashBoardChartsClient';
import {
  useNodesByTechnologyClient,
  useNodesClient,
} from '../../services/nodesClient';
import { isInternalUser } from '../../utils/AuthProvider';
import { TechnologyType } from '../../utils/denseware-client-js/client/types';
import { saveSearchParamsInStorage } from '../../utils/GetSearchParams';
import NodeListSelection from '../NodeListSelection/NodeListSelection';
import DisplayTagComponent, {
  DisplayTagItem,
  SelectedTag,
} from '../Select/DisplayTagComponent';
import MultiSelect from '../Select/MultiSelect';
import ChartWidth from './ChartWidth';

type ChartsSectionProps = {
  dashboardId: string;
  showFilters?: boolean | undefined;
  technologyType?: TechnologyType;
  allPlmns?: string[];
};

const ChartsSection: FC<ChartsSectionProps> = (props) => {
  const { dashboardId, showFilters = false, allPlmns, technologyType } = props;
  const isInternal = isInternalUser();

  const response = useDashBoardData(dashboardId);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: nodesData,
    isSuccess: nodesIsSuccess,
    isFetching: nodesIsFetching,
  } = useNodesByTechnologyClient({
    plmns: allPlmns,
    technologyType: technologyType ?? '4G',
  });

  const selectedNodesString = searchParams.get('selectedNodes') ?? '[]';
  const selectedNodes: { value: string; label: string; id: string }[] =
    JSON.parse(selectedNodesString);

  const nodesQuery = useNodesClient({
    plmns: allPlmns,
  });

  const allowedSelectedNodes =
    nodesQuery.status === 'success'
      ? selectedNodes.map((n) => {
          const nodeIds = Object.keys(nodesQuery.data);
          const foundNode = n.id
            ? nodeIds.includes(n.id)
            : nodeIds.includes(n.value);

          const selected: DisplayTagItem = {
            ...n,
            status: foundNode ? 'active' : 'disabled',
            ...(foundNode
              ? false
              : {
                  disabledMessage:
                    'This node is not available for this Customer',
                }),
          };

          return selected;
        })
      : [];
  const listItemClicked = (listName: string, option: SelectedTag) => {
    const { label, value: val, id } = option;

    setSearchParams((prev: URLSearchParams) => {
      const upperCaseName =
        listName.charAt(0).toUpperCase() + listName.slice(1);
      const selectedName = prev.get(`selected${upperCaseName}`);
      if (selectedName !== null) {
        const currentValue = { label: label, value: val, id };
        const urlParamName = JSON.parse(selectedName);
        urlParamName.push(currentValue);
        const stringsOfSelectedTags = JSON.stringify(urlParamName);
        if (listName === 'nodes') {
          const prevSelected = prev.get('selectedNodes') ?? '';
          const arrayValueOfPrevSelected = JSON.parse(prevSelected);
          const itemExists = arrayValueOfPrevSelected.filter(
            (obj: { label: string; value: string }) =>
              obj.value === currentValue.value
          );
          if (itemExists.length < 1) {
            prev.set('selectedNodes', stringsOfSelectedTags);
          }
        } else if (listName === 'cells') {
          const prevSelected = prev.get('selectedCells') ?? '';
          const arrayValueOfPrevSelected = JSON.parse(prevSelected);
          const itemExists = arrayValueOfPrevSelected.filter(
            (obj: { label: string; value: string }) =>
              obj.value === currentValue.value
          );
          if (itemExists.length < 1) {
            prev.set('selectedCells', stringsOfSelectedTags);
          }
        }
        return prev;
      } else {
        const selectedNames = [
          {
            label: label,
            value: val,
            id,
          },
        ];
        const initialStringValue = JSON.stringify(selectedNames);
        if (listName === 'nodes') {
          prev.set('selectedNodes', initialStringValue);
        } else if (listName === 'cells') {
          prev.set('selectedCells', initialStringValue);
        }
      }

      return prev;
    });
    saveSearchParamsInStorage();
  };

  const onDelete = (listName: string, itemValue: string) => {
    setSearchParams((prev: URLSearchParams) => {
      const upperCaseName =
        listName.charAt(0).toUpperCase() + listName.slice(1);
      const selectedName = `selected${upperCaseName}`;
      const getValuesOfListName = prev.get(`${selectedName}`) ?? '';
      const arrValues = JSON.parse(getValuesOfListName);

      const deleteValue = arrValues.filter((ele: { value: string }) => {
        return ele.value !== itemValue;
      });

      if (deleteValue.length === 0) {
        prev.delete(selectedName);
      } else {
        prev.set(selectedName, JSON.stringify(deleteValue));
      }
      return prev;
    });
    saveSearchParamsInStorage();
  };

  if (response.status === 'success') {
    return (
      <Box>
        {showFilters ? (
          <>
            <VStack>
              <Heading>{response.data.data.data.title}</Heading>
              <Heading size="md">Last 30 Days</Heading>
            </VStack>
            <Grid templateColumns="repeat(4, 1fr)" gap={2}>
              {isInternal ? (
                <GridItem>
                  <MultiSelect
                    listName="nodes"
                    isLoading={nodesIsFetching}
                    options={
                      nodesIsSuccess
                        ? Object.entries(nodesData)
                            .map(([nodeId, n]) => ({
                              label: n.nodeName,
                              value: n.nodeName,
                              id: nodeId,
                            }))
                            .sort((a, b) => {
                              return a.label.localeCompare(b.label);
                            })
                        : []
                    }
                    onSelect={listItemClicked}
                  />
                </GridItem>
              ) : null}
              <GridItem pl={'10px'} pt={'25px'}>
                <NodeListSelection showAddItemButton={false} />
              </GridItem>
              <GridItem colSpan={2}>
                <DisplayTagComponent
                  listName="nodes"
                  selectedTags={allowedSelectedNodes}
                  deleteTagFunc={onDelete}
                />
              </GridItem>
            </Grid>
          </>
        ) : null}

        <Grid
          padding={'20px'}
          // mt={'20px'}
          gap={`20px`}
          templateColumns={'1fr 1fr'}
        >
          {response.data.data.data.charts.map((dashboardChart, i) => {
            const width = dashboardChart.width;
            return (
              <ChartWidth
                key={`${dashboardChart.id}-${i}`}
                width={width}
                chartId={dashboardChart.id}
              />
            );
          })}
        </Grid>
      </Box>
    );
  }
  return null;
};

export default ChartsSection;
