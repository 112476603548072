import { Box, Divider, Flex, Heading, Tag } from '@chakra-ui/react';
import { set, sub } from 'date-fns';
import { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ChartFiltersContext } from '../../contexts/ChartFiltersContext';
import { usePlmnQueryClient } from '../../services/contextClient';
import useDashQueryClient from '../../services/dashClient';
import { useNodesClient } from '../../services/nodesClient';
import { isInternalUser } from '../../utils/AuthProvider';
import {
  Dashboard,
  DashboardType,
  TechnologyType,
} from '../../utils/denseware-client-js/client/types';
import ChartsSection from '../Charts/ChartsSection';
import ListButton from '../ListButton/ListButton';

const prevMonth = set(sub(new Date(), { days: 30 }), {
  hours: 0,
  minutes: 0,
  seconds: 0,
});
const today = set(new Date(), { hours: 23, minutes: 59, seconds: 59 });

const DashboardList = () => {
  const isInternal = isInternalUser();
  const dashboardType: DashboardType[] = isInternal ? ['4G', '5G'] : ['4G'];
  const response = useDashQueryClient({
    dashboardType,
  });
  const [hoveredDashboardId, setHoveredDashboardId] = useState<null | string>(
    null
  );
  const [techDashboardType, setTechDashboardType] = useState<string>('4G');

  const plmnQuery = usePlmnQueryClient();

  const nodesQuery = useNodesClient({
    plmns: plmnQuery.data?.allPlmns,
  });

  if (
    plmnQuery.status === 'success' &&
    nodesQuery.status === 'success' &&
    response.status === 'success'
  ) {
    const dashboards = response.data.data.data;
    const dashboardsByType = dashboards.reduce(
      (acc: { [K in DashboardType]?: Dashboard[] }, dashboard) => {
        const type = dashboard.dashboardType;
        const existingType = acc[type];

        if (existingType) {
          existingType.push(dashboard);
        } else {
          acc[type] = [dashboard];
        }
        return acc;
      },
      {}
    );

    const { allPlmns } = plmnQuery.data;
    const nodeNames = Object.keys(nodesQuery.data).map((n) => {
      return {
        label: n,
        value: n,
        id: n,
      };
    });

    return (
      <Flex p="3" overflow="hidden">
        <Box flexGrow="1" pr="3">
          <Scrollbars>
            <Box>
              {Object.entries(dashboardsByType)
                .sort((a, b) => {
                  const [aName] = a;
                  const [bName] = b;

                  return aName.localeCompare(bName);
                })
                .map(([dashboardType, dashboards]) => {
                  return (
                    <Box key={dashboardType}>
                      <Heading size="md">{dashboardType}</Heading>
                      <Divider mb="3" />
                      {dashboards.map((item) => {
                        return (
                          <ListButton
                            isActive={item.id === hoveredDashboardId}
                            key={item.id}
                            itemId={item.id}
                            title={item.title}
                            onMouseEnter={() => {
                              setHoveredDashboardId(item.id);
                              setTechDashboardType(dashboardType);
                            }}
                            rightAddOn={<Tag>{item.dashboardType}</Tag>}
                          />
                        );
                      })}
                    </Box>
                  );
                })}
            </Box>
          </Scrollbars>
        </Box>
        <Box flexGrow="2" borderRadius="5px">
          <Scrollbars>
            {hoveredDashboardId ? (
              <ChartFiltersContext.Provider
                value={{
                  plmns: allPlmns,
                  cellNumbers: [],
                  interval: '86400',
                  nodeNames,
                  dateAndTimeEnd: today.toISOString(),
                  dateAndTimeStart: prevMonth.toISOString(),
                  nodeToggle: 'combine-nodes',
                  autoRefresh: false,
                }}
              >
                <ChartsSection
                  dashboardId={hoveredDashboardId}
                  allPlmns={allPlmns}
                  technologyType={techDashboardType as TechnologyType}
                  showFilters={true}
                />
              </ChartFiltersContext.Provider>
            ) : null}
          </Scrollbars>
        </Box>
      </Flex>
    );
  }

  return null;
};

export default DashboardList;
