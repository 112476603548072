import { useQuery } from '@tanstack/react-query';
import denseairClient from './denseairClient';

const useChartClient = (chartId: string) => {
  return useQuery({
    queryKey: ['chart', chartId],
    queryFn: () => denseairClient.metadata.getChart({ chartId }),
  });
};

const useChartsClient = (chartIds: string[]) => {
  const reqs = chartIds.map((chartId) => {
    return denseairClient.metadata.getChart({ chartId });
  });

  return useQuery({
    queryFn: () => Promise.all(reqs),
    queryKey: ['charts', chartIds],
  });
};

export { useChartClient, useChartsClient };
