import { Flex } from '@chakra-ui/react';
import _find from 'lodash/find';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import { useChartClient } from '../../services/chartClient';
import { useMultipleMeasuresPerNodeClient } from '../../services/measureClient';
import { useNodesClient } from '../../services/nodesClient';
import { IntervalEnum } from '../../utils/denseware-client-js/client/types';
import { perSeriesResponseToChartData } from '../../utils/transformChartData';
import { ListOfLines } from '../lineChart/LineChart';
import { SelectedTag } from '../Select/DisplayTagComponent';
import DashboardChart from './DashboardChart';
import GraphOperations from './GraphOperations';

type PerNodeChartProps = {
  chartId: string;
  plmns: string[];
  nodeNames: SelectedTag[];
  dateAndTimeStart: string;
  dateAndTimeEnd: string;
  interval: IntervalEnum;
  autoRefresh?: boolean | undefined;
};

const PerNodeChart: FC<PerNodeChartProps> = (props) => {
  const {
    chartId,
    plmns,
    nodeNames,
    dateAndTimeStart,
    dateAndTimeEnd,
    interval,
    autoRefresh = false,
  } = props;
  const { ref, inView } = useInView();
  const { data: chartData } = useChartClient(chartId);

  const [searchParams] = useSearchParams();

  const timeZone = searchParams.get('timezone') ?? 'Etc/UTC';
  const isTimeZoneUTC = timeZone === 'Etc/UTC';

  const nodesResponse = useNodesClient({ plmns });
  const nodeIds =
    nodesResponse.status === 'success'
      ? nodeNames
          .filter((n) =>
            _find(
              nodesResponse.data,
              (node) => node.nodeName.toLowerCase() === n.value.toLowerCase()
            )
          )
          .map((nodeName) => {
            const foundNode = Object.entries(nodesResponse.data).find(
              (entry) => {
                const value = entry[1];
                return value.nodeName === nodeName.value;
              }
            );

            if (foundNode) {
              return foundNode[0];
            }

            return nodeName.value;
          })
      : [];

  const measuresResponses = useMultipleMeasuresPerNodeClient(
    chartData ? chartData.data.data.series.map((s) => s.measureId) : [],
    {
      plmns,
      nodeId: nodeIds,
      dateAndTimeStart,
      dateAndTimeEnd,
      interval,
      enabled: inView,
      autoRefresh,
    }
  );

  const measuresData = measuresResponses.isSuccess
    ? measuresResponses.data
    : [];

  const seriesTitles = chartData
    ? chartData.data.data.series.map((s) => s.title)
    : [];

  const nodes = nodesResponse.status === 'success' ? nodesResponse.data : {};

  const labels: string[] = [];
  // 2023-03-04T06:46:51Z
  const dataForChart = measuresData.flatMap((measureDataForAllNodes, i) => {
    const measureTitle = seriesTitles[i];

    const { data, ids } = perSeriesResponseToChartData(
      measureDataForAllNodes.data.data
    );

    ids.forEach((id) => {
      const n = nodes[id];
      if (seriesTitles.length > 1) {
        labels.push(`${measureTitle} - ${n?.nodeName}`);
      } else {
        labels.push(n ? n.nodeName : '');
      }
    });
    return data;
  });

  const allData: ListOfLines = dataForChart.map((chartD) => {
    return { data: chartD, additionalData: [] };
  });

  const arrayOfMetricsData = measuresData.map((resp) => resp.data.data);

  const addedNodeName = arrayOfMetricsData.map((seriesData) => {
    return Object.entries(seriesData).flatMap((entry) => {
      const [nodeName, values] = entry;
      return values.map((v) => {
        return { nodeName, ...v };
      });
    });
  });

  const exportData = addedNodeName.flatMap((listOfPoints, i) => {
    return listOfPoints.map((data) => {
      return {
        ...data,

        ...(!isTimeZoneUTC && {
          [`dateAndTimeStart (${
            DateTime.fromISO(data.DateAndTimeStart).setZone(timeZone)
              .offsetNameLong
          })`]: DateTime.fromISO(data.DateAndTimeStart)
            .setZone(timeZone)
            .toISO({ suppressMilliseconds: true }),
          [`dateAndTimeEnd (${
            DateTime.fromISO(data.DateAndTimeStart).setZone(timeZone)
              .offsetNameLong
          })`]: DateTime.fromISO(data.DateAndTimeEnd)
            .setZone(timeZone)
            .toISO({ suppressMilliseconds: true }),
        }),

        seriesName: seriesTitles[i],
      };
    });
  });

  return (
    <Flex ref={ref} height="100%" flexDirection="column">
      <DashboardChart
        chartId={chartId}
        data={allData}
        description={chartData?.data.data.description}
        labels={labels}
        title={chartData?.data.data.title}
        measureDataStatus={measuresResponses.status}
        refetch={measuresResponses.refetch}
        y0Label={chartData?.data.data.axisLabels.y0Label}
      />
      {measuresResponses.isSuccess ? (
        <GraphOperations
          lastFetched={measuresResponses.dataUpdatedAt}
          refetch={measuresResponses.refetch}
          exportName={'Export'}
          title={chartData?.data.data.title}
          chartData={exportData}
          extraRowName={'Selected Nodes'}
          hasGlobalSLAs={chartData?.data.data.series.some(
            (s) => s.thresholds.length > 0
          )}
        />
      ) : null}
    </Flex>
  );
};

export default PerNodeChart;
