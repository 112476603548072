import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const unstyledButton = defineStyle({
  background: '#EDF2F7',
  padding: '5px 10px',
});

const underLineButton = defineStyle({
  fontWeight: 100,
  background: 'white',
  _hover: {
    textDecoration: 'underline',
  },
});
const unstyledLinkButton = defineStyle({
  fontWeight: 100,
  padding: '5px 10px',
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  _hover: {
    background: 'lightGrey',
  },
});

const solidButton = defineStyle({
  background: 'primary',
  color: 'white',
  fontWeight: 'normal',
  _hover: { background: 'primaryLightFifteenPercent' },
});

const secondaryButton = defineStyle({
  background: 'white',
  border: '1px solid gray',
  _hover: { background: 'primaryDarkFifteenPercent', color: 'white' },
});

const warningButton = defineStyle({
  background: 'primary',
  _hover: {
    cursor: 'pointer',
    background: 'errorRed',
  },
});

const buttonTheme = defineStyleConfig({
  variants: {
    solid: solidButton,
    secondary: secondaryButton,
    warning: warningButton,
    unstyled: unstyledButton,
    unstyledLink: unstyledLinkButton,
    underLine: underLineButton,
  },
});

export default buttonTheme;
