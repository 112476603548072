import { Box, Center, Flex, HStack } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { categoryColors } from '../../utils/seriesColors';

type LegendProps = {
  items: string[];
  hiddenItems: string[];
  onClickItem: (item: string) => void;
};

const Legend: FC<LegendProps> = memo((props) => {
  const { items, hiddenItems, onClickItem } = props;

  return (
    <Center>
      <HStack wrap="wrap" justifyContent="center" spacing="3" cursor="pointer">
        {items.map((item, i) => {
          return (
            <Flex
              key={item}
              fontSize="sm"
              alignItems="center"
              onClick={() => {
                if (onClickItem) {
                  onClickItem(item);
                }
              }}
            >
              <Box
                width="2em"
                height="1em"
                lineHeight="1"
                backgroundColor={categoryColors[i] ?? 'black'}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={categoryColors[i] ?? 'black'}
                mr="1"
              />{' '}
              <span
                style={{
                  textDecoration: hiddenItems.includes(item)
                    ? 'line-through'
                    : 'none',
                }}
              >
                {item}
              </span>
            </Flex>
          );
        })}
      </HStack>
    </Center>
  );
});

Legend.displayName = 'ChartLegend';

export default Legend;
