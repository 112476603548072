import { useQuery } from '@tanstack/react-query';
import { IntervalEnum } from '../utils/denseware-client-js/client/types';
import denseairClient from './denseairClient';

type GetMeasureId = string[];

type GetMeasureOptions = {
  plmns: string[];
  nodeId: string[];
  cellNumber?: string[] | undefined;
  dateAndTimeStart: string;
  dateAndTimeEnd: string;
  interval: IntervalEnum;
  enabled: boolean;
  autoRefresh: boolean;
};

type GetMeasureByNodeOptions = {
  plmns: string[];
  nodeId: string[];
  dateAndTimeStart: string;
  dateAndTimeEnd: string;
  interval: IntervalEnum;
  enabled: boolean;
  autoRefresh: boolean;
};

const useGetMeasuresListClient = () => {
  return useQuery(['MeasuresList'], denseairClient.measures.getMeasuresList);
};

// PerNode i view calls this one
const useMultipleMeasuresClient = (
  measureIds: GetMeasureId,
  options: GetMeasureOptions
) => {
  const {
    plmns,
    nodeId,
    cellNumber,
    dateAndTimeStart,
    dateAndTimeEnd,
    interval,
    enabled,
    autoRefresh,
  } = options;

  const nodeIdsToUse = nodeId.length === 0 ? null : nodeId;

  return useQuery({
    queryFn: async () => {
      const allMeasures = measureIds.map((measureId) => {
        return denseairClient.measures.getMeasuresForMeasureId({
          path: { measureId },
          params: {
            plmn: plmns,
            nodeId: nodeIdsToUse,
            cellNumber,
            dateAndTimeStart,
            dateAndTimeEnd,
            interval,
          },
        });
      });

      return Promise.all(allMeasures);
    },
    enabled,
    queryKey: [
      'measures',
      measureIds,
      plmns,
      nodeId,
      cellNumber,
      dateAndTimeStart,
      dateAndTimeEnd,
      interval,
    ],
    staleTime: 1000 * 60 * 3,
    refetchInterval: autoRefresh ? 1000 * 30 : false,
  });
};

// combine Node calls this API
const useMultipleMeasuresPerNodeClient = (
  measureIds: GetMeasureId,
  options: GetMeasureByNodeOptions
) => {
  const {
    plmns,
    dateAndTimeStart,
    dateAndTimeEnd,
    interval,
    nodeId,
    enabled,
    autoRefresh,
  } = options;

  const nodeIdsToUse = nodeId.length === 0 ? null : nodeId;

  return useQuery({
    enabled,
    queryFn: async () => {
      const allMeasures = measureIds.map((measureId) => {
        return denseairClient.nodes.getMeasuresForMeasureIdByNode({
          path: { measureId },
          params: {
            plmn: plmns,
            nodeId: nodeIdsToUse,
            dateAndTimeStart,
            dateAndTimeEnd,
            interval,
          },
        });
      });
      return Promise.all(allMeasures);
    },
    queryKey: [
      'measuresByNode',
      measureIds,
      plmns,
      nodeId,
      dateAndTimeStart,
      dateAndTimeEnd,
      interval,
    ],
    staleTime: 1000 * 60 * 3,
    refetchInterval: autoRefresh ? 1000 * 30 : false,
  });
};

export {
  useMultipleMeasuresClient,
  useMultipleMeasuresPerNodeClient,
  useGetMeasuresListClient,
};
