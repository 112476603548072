import { ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  List,
  ListItem,
  Spinner,
  useOutsideClick,
} from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';

type SelectOption = {
  label: string;
  value: string;
  id: string;
};

type SelectProps = {
  listName: string;
  options: SelectOption[];
  onSelect: (listName: string, options: SelectOption) => void;
  isLoading: boolean;
  showTitle?: boolean;
};

const MultiSelect: FC<SelectProps> = ({
  listName,
  options,
  onSelect,
  isLoading,
  showTitle = true,
}) => {
  const width = '100%';
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapper = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const newOptions = options.filter((option) => {
    return option.label
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase());
  });

  const showOptions = () => {
    return setIsOpen((prev) => {
      return !prev;
    });
  };

  useOutsideClick({
    ref: wrapper,
    handler: () => {
      return setIsOpen(false);
    },
  });

  return (
    <>
      <Box ref={wrapper} id={`multiSelect-${listName}`} position={'relative'}>
        {showTitle ? `Select ${listName}` : null}
        <Box
          id={`multiselect-dropdown-${listName}`}
          position={'relative'}
          display={'grid'}
          gridTemplateColumns={width}
          userSelect={'none'}
          onClick={showOptions}
        >
          <Box
            height={'40px'}
            borderRadius={5}
            border={'1px solid #345566'}
            p={'0.7em 0.5em'}
            display={'flex'}
            alignItems={'center'}
            position={'relative'}
          >
            {listName}
            <Box
              id={`open-dropdown-${listName}`}
              height={'1.2em'}
              display={'flex'}
              alignItems={'center'}
              position={'absolute'}
              right={'0.5em'}
              alignContent={'end'}
              borderLeft={'1px solid'}
              borderLeftColor={'gray'}
            >
              <ChevronDownIcon
                tabIndex={0}
                fontSize={30}
                fontWeight={'900'}
                padding={'0 0 0 5px'}
                cursor={'pointer'}
                color={'gray'}
              />
            </Box>
          </Box>
        </Box>
        <Box
          mt={'15px'}
          padding={'0.3em 0'}
          border={'1px solid'}
          borderColor={'gray'}
          borderRadius={'4px'}
          display={isOpen ? ' grid' : 'none'}
          background={'white'}
          position={'absolute'}
          zIndex={1}
          top={'55px'}
          width={width}
        >
          {isLoading ? (
            <Box m="10px">
              <Spinner />
            </Box>
          ) : options.length === 0 ? (
            <Box p="3">No results</Box>
          ) : (
            <>
              <Box>
                <Input
                  position={'relative'}
                  type={'text'}
                  height={'35px'}
                  width={'90%'}
                  borderRadius={'3px'}
                  m={'10px'}
                  zIndex={5}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
                {searchTerm.length > 0 ? (
                  <SmallCloseIcon
                    position={'absolute'}
                    top={'25px'}
                    right={'10%'}
                    zIndex={10}
                    aria-label="clear-text"
                    onClick={() => {
                      setSearchTerm(() => {
                        return '';
                      });
                    }}
                    _hover={{
                      cursor: 'pointer',
                    }}
                  />
                ) : null}
              </Box>

              <List
                maxHeight={'200px'}
                overflow={'hidden'}
                overflowY={'scroll'}
              >
                {newOptions.map((option, i) => {
                  return (
                    <ListItem
                      tabIndex={i}
                      id={`multiselect-list-item-${option.value}`}
                      key={`${option.value}-${i}`}
                      cursor={'pointer'}
                      fontSize={'1em'}
                      fontFamily={'poppins'}
                      p={'8px 0 8px 10px '}
                      _hover={{
                        background: 'primary',
                        color: 'brand.white',
                        transition: 'all 0.4s',
                      }}
                      onClick={() => onSelect(listName, option)}
                    >
                      {option.label}
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MultiSelect;
