import { Box, Checkbox, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { FC, Fragment, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useChartControlsContext } from '../../contexts/ChartControlsContext';
import ExportData from '../ExportData/ExportData';
import RefreshCharts from './RefreshCharts';

type GraphOperationsProps = {
  lastFetched: number;
  refetch: () => void;
  exportName: string;
  title?: string | undefined;
  chartData: Array<Record<string, number | string | null>>;
  // chartData: { [heading: string]: number | string | null }[];
  extraRowName?: string;
  extraRowData?: string[];
  hasGlobalSLAs?: boolean | undefined;
  enableCalendarAverage?: boolean | undefined;
  enableRollingAverage?: boolean | undefined;
};

const GraphOperations: FC<GraphOperationsProps> = (props) => {
  const {
    lastFetched,
    refetch,
    exportName,
    title,
    chartData,
    extraRowName,
    extraRowData,
    hasGlobalSLAs = false,
    enableCalendarAverage,
    enableRollingAverage,
  } = props;

  const [searchParams] = useSearchParams();

  const timeIntervals = searchParams.get('timeInterval') ?? '86400';

  const [controlsState, controlsActions] = useChartControlsContext();

  useEffect(() => {
    if (timeIntervals !== '86400') {
      controlsActions.hideCalendarAvg();
      controlsActions.hideRollingAverage();
    }
  }, [timeIntervals, controlsActions]);

  return (
    <Flex
      fontSize="x-small"
      alignItems="center"
      justifyContent="space-between"
      mb="0"
      mt="auto"
    >
      <Box ml="0" mr="auto" flexShrink="0">
        <RefreshCharts lastFetched={lastFetched} refetch={refetch} />
      </Box>
      <Fragment>
        <Checkbox
          ml="auto"
          mr="3"
          onChange={() => {
            controlsActions.toggleShowTrends();
          }}
          isChecked={controlsState.showTrends}
          size="sm"
        >
          Trendline
        </Checkbox>
        <Box ml="0" mr="3">
          <Checkbox
            isDisabled={!enableCalendarAverage}
            onChange={() => {
              controlsActions.toggleShowCalendarAvg();
            }}
            isChecked={controlsState.showCalendarAvg}
            size="sm"
          >
            Monthly Running Average
          </Checkbox>
          <Tooltip label="Show running average per calendar month. Resets at the start of the month. Time Interval of 1 day must to selected to show">
            <Icon
              name="help"
              color="#666666"
              fontSize="14px"
              ml="1"
              verticalAlign="initial"
            />
          </Tooltip>
        </Box>
        <Box ml="0" mr="3">
          <Checkbox
            isDisabled={!enableRollingAverage}
            onChange={() => {
              controlsActions.toggleShowRollingAverage();
            }}
            isChecked={controlsState.showRollingAverage}
            size="sm"
          >
            30d Rolling Average
          </Checkbox>
          <Tooltip label="Show 30 day rolling average. More than 30 days of data and Time Interval of 1 day must to selected to show.">
            <Icon
              name="help"
              color="#666666"
              fontSize="14px"
              ml="1"
              verticalAlign="initial"
            />
          </Tooltip>
        </Box>
        <Box ml="0" mr="3">
          <Checkbox
            isDisabled={!hasGlobalSLAs}
            onChange={() => {
              controlsActions.toggleShowGlobalThresholds();
            }}
            isChecked={controlsState.showGlobalThresholds}
            size="sm"
          >
            Global SLAs
          </Checkbox>
          {!hasGlobalSLAs ? (
            <Tooltip label="No global SLAs set">
              <Icon
                name="help"
                color="#666666"
                fontSize="14px"
                ml="1"
                verticalAlign="initial"
              />
            </Tooltip>
          ) : null}
        </Box>
      </Fragment>
      <ExportData
        exportName={exportName}
        title={title}
        chartData={chartData}
        extraRowName={extraRowName}
        extraRowData={extraRowData}
      />
    </Flex>
  );
};

export default GraphOperations;
