import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { ChartControlsProvider } from '../../contexts/ChartControlsContext';
import { ChartFiltersContext } from '../../contexts/ChartFiltersContext';
import { useChartClient } from '../../services/chartClient';
import CombinedNodeChart from '../dashboardChart/CombinedNodeChart';
import PerNodeChart from '../dashboardChart/PerNodeChart';

type ChartWidthProps = {
  width: 50 | 100;
  chartId: string;
};

const ChartWidth: FC<ChartWidthProps> = (props) => {
  const { width, chartId } = props;

  const filters = useContext(ChartFiltersContext);
  const { data: chartData, status: chartDataStatus } = useChartClient(chartId);

  if (filters === null) {
    return (
      <Box
        gridColumn={width === 100 ? '1 / span 2' : undefined}
        borderRadius={'8px'}
      />
    );
  }

  if (chartDataStatus === 'success' && filters.plmns.length === 0) {
    return (
      <Box
        gridColumn={width === 100 ? '1 / span 2' : undefined}
        borderRadius={'8px'}
      >
        <Flex height="100%" flexDirection="column">
          <Center flexGrow="0" flexShrink="0" mt="0" mb="0">
            <Heading size="sm" mb="3">
              {chartData.data.data.title}
              {chartData.data.data.description ? (
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Icon
                      name="help"
                      color="#666666"
                      ml="2"
                      fontSize="14px"
                      verticalAlign="initial"
                    />
                  </PopoverTrigger>
                  <PopoverContent width="lg">
                    <PopoverBody whiteSpace="pre-line">
                      <Box fontSize="sm" fontWeight="normal">
                        {chartData.data.data.description}
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : null}
            </Heading>{' '}
          </Center>
          <Box>
            <Center>No data available</Center>
          </Box>
        </Flex>
      </Box>
    );
  }

  return (
    <ChartControlsProvider>
      <Box gridColumn={width === 100 ? '1 / span 2' : undefined} px="3" py="4">
        {filters.nodeToggle === 'per-node' ? (
          <PerNodeChart
            chartId={chartId}
            plmns={filters.plmns}
            nodeNames={filters.nodeNames}
            dateAndTimeStart={filters.dateAndTimeStart}
            dateAndTimeEnd={filters.dateAndTimeEnd}
            interval={filters.interval}
            autoRefresh={filters.autoRefresh}
          />
        ) : (
          <CombinedNodeChart
            chartId={chartId}
            plmns={filters.plmns}
            dateAndTimeStart={filters.dateAndTimeStart}
            dateAndTimeEnd={filters.dateAndTimeEnd}
            nodeNames={filters.nodeNames}
            interval={filters.interval}
            cellNumbers={filters.cellNumbers}
            autoRefresh={filters.autoRefresh}
          />
        )}
      </Box>
    </ChartControlsProvider>
  );
};

export default ChartWidth;
