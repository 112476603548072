const STORAGE_KEY = 'SearchParams';

const saveSearchParamsInStorage = () => {
  const urlSearchParamsString = window.location.search.slice(1);
  if (urlSearchParamsString !== '') {
    const urlSearchParams = new URLSearchParams(urlSearchParamsString);
    urlSearchParams.delete('token');
    urlSearchParams.delete('timezone');
    localStorage.setItem(STORAGE_KEY, urlSearchParams.toString());
  }
};

const setSearchParamsFromStorage = () => {
  const params = localStorage.getItem(STORAGE_KEY);
  const searchExist = window.location.search;
  if (params !== null && params !== '' && searchExist == '') {
    const newLocation =
      window.location.origin + window.location.pathname + '?' + params;
    const loc = new URL(newLocation);
    window.location.replace(loc.href);
  }
};

const removeSearchFromStorage = () => {
  localStorage.removeItem(STORAGE_KEY);
};

export {
  saveSearchParamsInStorage,
  setSearchParamsFromStorage,
  removeSearchFromStorage,
};
