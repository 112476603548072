import { RepeatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
} from '@chakra-ui/react';
import { UseQueryResult } from '@tanstack/react-query';
import { FC, Fragment, useContext } from 'react';
import { useChartControlsContext } from '../../contexts/ChartControlsContext';
import { ChartFiltersContext } from '../../contexts/ChartFiltersContext';
import { useFilterThresholds } from '../../hooks/useFilterThresholds';
import { useChartClient } from '../../services/chartClient';
import { timeIntervalOptions } from '../../utils/config';
import LineChart, { ListOfLines, ThresholdLine } from '../lineChart/LineChart';
import EditMiniModalWrapper from '../PersonalizationModalOptions/EditMiniModalWrapper';

type DashboardChartProps = {
  chartId: string;
  measureDataStatus: UseQueryResult['status'];
  data: ListOfLines;
  labels?: string[] | undefined;
  title?: string | undefined;
  refetch: () => void;
  y0Label?: undefined | null | string;
  description?: string | null | undefined;
};

const DashboardChart: FC<DashboardChartProps> = (props) => {
  const {
    chartId,
    measureDataStatus,
    data,
    labels,
    title,
    refetch,
    y0Label,
    description,
  } = props;

  const filters = useContext(ChartFiltersContext)!;
  const [controlsState] = useChartControlsContext();
  const { dateAndTimeStart, dateAndTimeEnd, interval } = filters;

  const { data: chartData, status: chartDataStatus } = useChartClient(chartId);
  const userThresholds = useFilterThresholds(chartId);

  const thresholds: ThresholdLine[] = userThresholds
    ? userThresholds.map((t) => ({
        ...t,
        style: 'dash-sm',
        color: t.color ?? 'rgb(0,0,0)',
      }))
    : [];

  if (controlsState.showGlobalThresholds && chartDataStatus === 'success') {
    chartData.data.data.series.forEach((s) => {
      s.thresholds.forEach((t) => {
        const value = (() => {
          if (t.targetMax === null && t.targetMin === null) {
            return null;
          }

          if (t.targetMax === null) {
            return t.targetMin;
          }

          if (t.targetMin === null) {
            return t.targetMax;
          }

          if (t.targetMax > t.targetMin) {
            return t.targetMax;
          }
          if (t.targetMin > t.targetMax) {
            return t.targetMin;
          }

          return null;
        })();

        if (value) {
          thresholds.push({
            style: 'dash-lg',
            value: value,
            color:
              t.level === 'critical'
                ? 'rgba(112, 2, 2, 1)'
                : t.level === 'warning'
                ? 'rgba(218, 182, 0, 1)'
                : t.level === 'normal'
                ? 'rgba(37, 83, 37, 1)'
                : 'rgba(31, 120, 180, 1)',
          });
        }
      });
    });
  }

  const spanGapsSize = timeIntervalOptions.find((o) => {
    return o.value === interval;
  })?.min;

  return (
    <Fragment>
      <Center flexGrow="0" flexShrink="0" mt="0" mb="0">
        <Heading size="sm" mb="3">
          {title}
          {description ? (
            <Popover trigger="hover">
              <PopoverTrigger>
                <Icon
                  name="help"
                  color="#666666"
                  ml="2"
                  fontSize="14px"
                  verticalAlign="initial"
                />
              </PopoverTrigger>
              <PopoverContent width="lg">
                <PopoverBody whiteSpace="pre-line">
                  <Box fontSize="sm" fontWeight="normal">
                    {description}
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : null}
        </Heading>
      </Center>
      <Flex justifyContent={'flex-end'}>
        <EditMiniModalWrapper
          measureIds={chartData?.data.data.series.map((s) => s.measureId)}
        />
      </Flex>
      {measureDataStatus === 'error' || chartDataStatus === 'error' ? (
        <Box>
          <Center>There was a problem loading the data</Center>
          <Center>
            <Button
              aria-label={'Retry'}
              rightIcon={<RepeatIcon />}
              onClick={() => {
                refetch();
              }}
            >
              Retry
            </Button>
          </Center>
        </Box>
      ) : (
        <Skeleton
          isLoaded={
            measureDataStatus === 'success' && chartDataStatus === 'success'
          }
        >
          {data.every(
            (dataset) => dataset.data && dataset.data.length === 0
          ) ? (
            <Box>
              <Center>No data available</Center>
            </Box>
          ) : (
            <LineChart
              chartData={data}
              seriesLabels={labels}
              title={title}
              y0Label={y0Label}
              min={new Date(dateAndTimeStart).getTime()}
              max={new Date(dateAndTimeEnd).getTime()}
              spanGapsSize={spanGapsSize}
              thresholdValue={thresholds}
              showTrendLine={controlsState.showTrends}
              // showRollingAverage={controlsState.showRollingAverage}
            />
          )}
        </Skeleton>
      )}
    </Fragment>
  );
};

export default DashboardChart;
