import axios from 'axios';
import { getToken } from '../utils/AuthProvider';

const densewareAxiosClient = axios.create({
  baseURL: import.meta.env.VITE_APP_DENSEWARE_API_URL,
});

densewareAxiosClient.interceptors.request.use((config) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default densewareAxiosClient;
