import { Box, Button, Center, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

const MainErrorPage: FC = () => {
  return (
    <Box>
      <Box>
        <Text align="center">Something has gone wrong.</Text>
        <Text align="center">
          You can try refreshing, or if that doesn&apos;t work, clear the
          storage and log in again.
        </Text>
      </Box>
      <Center mt="3">
        <HStack>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
          <Button
            onClick={() => {
              localStorage.clear();
              window.location.assign(`${window.location.origin}`);
            }}
          >
            Clear and login again
          </Button>
        </HStack>
      </Center>
    </Box>
  );
};

export default MainErrorPage;
