import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { Link, useMatches } from 'react-router-dom';

type MatchObject = ReturnType<typeof useMatches>[0];

type DWMatchObject = {
  handle?: { crumbName?: string | undefined };
} & MatchObject;

const BreadcrumbComponent = () => {
  const routeMatches = useMatches() as DWMatchObject[];

  const routeMatchesWithCrumb = routeMatches.filter((m) => {
    return m.handle && m.handle.crumbName;
  });

  if (routeMatchesWithCrumb.length > 1) {
    return (
      <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
        {routeMatchesWithCrumb.map((m) => {
          return (
            <BreadcrumbItem key={m.id}>
              <BreadcrumbLink color={'gray'} as={Link} to={m.pathname}>
                {m.handle && m.handle.crumbName
                  ? m.handle.crumbName
                  : m.params.dashboardId}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    );
  }

  return null;
};

export default BreadcrumbComponent;
