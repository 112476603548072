import { createContext } from 'react';
import { SelectedTag } from '../components/Select/DisplayTagComponent';
import { IntervalEnum } from '../utils/denseware-client-js/client/types';

export type NodeToggleOption = 'per-node' | 'combine-nodes';

type ChartFilters = {
  plmns: string[];
  dateAndTimeStart: string;
  dateAndTimeEnd: string;
  nodeNames: SelectedTag[];
  interval: IntervalEnum;
  cellNumbers: SelectedTag[];
  nodeToggle: NodeToggleOption;
  autoRefresh?: boolean | undefined;
};

export const ChartFiltersContext = createContext<ChartFilters | null>(null);
