import { Center, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import useDashBoardData from '../../services/dashBoardChartsClient';

type DashboardTitleProps = {
  dashboardId: string;
};

const DashboardTitle: FC<DashboardTitleProps> = (props) => {
  const { dashboardId } = props;
  const response = useDashBoardData(dashboardId);

  if (response.status === 'success') {
    return (
      <Center>
        <Heading mb={'20px'} color={'primary'} id={'dash-heading'}>
          {response.data.data.data.title}
        </Heading>
      </Center>
    );
  }
  return null;
};

export default DashboardTitle;
