import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Feature, point } from '@turf/turf';
import { FC, useState } from 'react';
import { BiDotsVertical } from 'react-icons/bi';
import { useNodesClient } from '../../services/nodesClient';
import { NodeId } from '../../utils/denseware-client-js/client/types';
import TagComponent from '../Select/TagComponent';
import AllNodesButton from './AllNodesButton';
import LinkButton from './LinkButton';

type ModalOptions = {
  dateAndTimeStart: string;
  dateAndTimeEnd: string;
  plmns: string[];
  nodeNames: PassedNodeName[];
  provider: string;
  country: string;
  deleteTagFunc: (id: string, item: string) => void;
};
export type SelectedNodesData = {
  nodeName: string;
  description?: string | undefined | null;
  nodeConfig: {
    hardware: string;
  };
  location: {
    altitude?: number | null | undefined;
    latitude?: number | null | undefined;
    longitude?: number | null | undefined;
  };
  networkProfile: {
    plmnConfig: Array<{ mcc: number; mnc: number }>;
  };
  id: NodeId;
};

type PassedNodeName = {
  label: string;
  value: NodeId;
};

const ActionsModal: FC<ModalOptions> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    dateAndTimeStart,
    plmns,
    nodeNames,
    provider,
    country,
    deleteTagFunc,
  } = props;
  const nodesResponse = useNodesClient({
    plmns,
  });

  const [selectedNodesArray, setSelectedNodesArray] = useState<NodeId[]>(
    nodeNames.map((nodeName) => {
      return nodeName.value;
    })
  );

  let selectedNodesList: SelectedNodesData[] = [];

  if (nodesResponse.status === 'success') {
    const allNodeValues = Object.entries(nodesResponse.data);

    selectedNodesList = allNodeValues
      .filter(([id]) => {
        const nodeIds = nodeNames.map((n) => n.value);
        const isInList = nodeIds.includes(id);

        return isInList;
      })
      .map(([nodeId, nodeObject]) => ({ ...nodeObject, id: nodeId }))
      .sort((a, b) => {
        return a.nodeName.localeCompare(b.nodeName);
      });
  }

  const allNodesWithLocation = selectedNodesList.filter((n) => {
    const hasLocation = n.location !== undefined && n.location.latitude;

    return hasLocation;
  });

  const checkedNodeList = selectedNodesList.filter((checkedNode) => {
    return selectedNodesArray.includes(checkedNode.id);
  });

  const chosenFeaturesWithLocation = checkedNodeList.reduce(
    (acc: Feature[], selectedNodes) => {
      if (
        selectedNodes?.location.longitude &&
        selectedNodes?.location.latitude
      ) {
        acc.push(
          point([
            selectedNodes.location.longitude,
            selectedNodes.location.latitude,
          ])
        );
      }
      return acc;
    },
    []
  );

  const selectedActionNodes = (nodeAction: string) => {
    const isSelected = selectedNodesArray.includes(nodeAction);

    if (isSelected) {
      const newArray = selectedNodesArray.filter((option) => {
        return nodeAction !== option;
      });
      setSelectedNodesArray(newArray);
    }
    if (!isSelected) {
      const newArray = [...selectedNodesArray, nodeAction];
      setSelectedNodesArray(newArray);
    }
  };

  const checkedItems: 'checked' | 'unchecked' | 'indeterminate' =
    selectedNodesArray.length === 0
      ? 'unchecked'
      : nodeNames.length === selectedNodesArray.length
      ? 'checked'
      : 'indeterminate';

  const checkBoxClicked = () => {
    if (checkedItems === 'checked') {
      setSelectedNodesArray([]);
    } else if (checkedItems === 'unchecked') {
      setSelectedNodesArray(nodeNames.map((n) => n.value));
    } else {
      setSelectedNodesArray(nodeNames.map((n) => n.value));
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        width={'40px'}
        height={'40px'}
        variant="secondary"
        mt={'3'}
      >
        <Icon as={BiDotsVertical} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent width={'100%'}>
          <ModalHeader>
            <Heading size="lg">Selected Nodes</Heading>
          </ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody>
            <Grid gridGap={'10px'} justifyContent={'center'}>
              <Flex justifyContent={'end'} gap={'3'} mb="3">
                <AllNodesButton
                  name={'Geo'}
                  fromDate={dateAndTimeStart}
                  points={chosenFeaturesWithLocation}
                  countryCode={country}
                  providerName={provider}
                  isDisabled={chosenFeaturesWithLocation.length === 0}
                />
                <AllNodesButton
                  name={'Monitor'}
                  fromDate={dateAndTimeStart}
                  selectedNodes={checkedNodeList}
                  countryCode={country}
                  providerName={provider}
                  isDisabled={chosenFeaturesWithLocation.length === 0}
                />
              </Flex>
              <Flex justifyContent={'end'}>
                <Checkbox
                  isChecked={checkedItems === 'checked'}
                  isIndeterminate={checkedItems === 'indeterminate'}
                  onChange={checkBoxClicked}
                />
                <Text pl={'10px'}>Select All</Text>
              </Flex>
              <SimpleGrid columns={3} spacingX="8" spacingY="3" mb="6">
                {selectedNodesList.map((nodeDetailsObj) => {
                  return (
                    <Flex
                      key={nodeDetailsObj.id}
                      justifyContent="flex-start"
                      alignItems="center"
                      borderRadius="10px"
                      bg=""
                    >
                      <Box ml="0" mr="auto">
                        <TagComponent
                          listName={'nodes'}
                          label={nodeDetailsObj.nodeName}
                          value={nodeDetailsObj.id}
                          delFunc={deleteTagFunc}
                        />
                      </Box>
                      <Box ml="auto" mr="2">
                        <Checkbox
                          verticalAlign={'middle'}
                          value={nodeDetailsObj.id}
                          isChecked={selectedNodesArray.includes(
                            nodeDetailsObj.id
                          )}
                          onChange={() => {
                            selectedActionNodes(nodeDetailsObj.id);
                            checkBoxClicked;
                          }}
                        />
                      </Box>
                      <Flex ml="2" mr="0">
                        <Box mr="1">
                          <LinkButton
                            countryCode={country}
                            providerName={provider}
                            longitude={nodeDetailsObj.location.longitude}
                            latitude={nodeDetailsObj.location.latitude}
                            fromDate={dateAndTimeStart}
                            name="G"
                            isDisabled={
                              allNodesWithLocation.filter(
                                (n) => n.nodeName === nodeDetailsObj.nodeName
                              ).length === 0
                            }
                          />
                        </Box>
                        <Box ml="1">
                          <LinkButton
                            countryCode={country}
                            providerName={provider}
                            fromDate={dateAndTimeStart}
                            nodeName={nodeDetailsObj.nodeName}
                            name="M"
                            isDisabled={false}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  );
                })}
              </SimpleGrid>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ActionsModal;
