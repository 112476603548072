import { RepeatIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC } from 'react';

type RefreshChartsProps = {
  lastFetched: number;
  refetch: () => void;
};

const RefreshCharts: FC<RefreshChartsProps> = (props) => {
  const { lastFetched, refetch } = props;
  return (
    <div>
      <div>Last fetched: {format(lastFetched, 'do MMM yyyy HH:mm:ss')}</div>
      <Button
        aria-label={'Refresh'}
        onClick={() => {
          refetch();
        }}
        rightIcon={<RepeatIcon />}
        size="xs"
        fontWeight="normal"
        variant="unstyled"
      >
        Refresh
      </Button>
    </div>
  );
};

export default RefreshCharts;
