import { Box, SimpleGrid } from '@chakra-ui/layout';
import {
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import {
  differenceInMilliseconds,
  differenceInSeconds,
  isAfter,
  isSameDay,
  set,
  sub,
  subYears,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import useGetContext, {
  usePlmnByProviderQueryClient,
} from '../../services/contextClient';
import {
  useNodesByTechnologyClient,
  useNodesClient,
} from '../../services/nodesClient';
import { isInternalUser } from '../../utils/AuthProvider';
import { timeIntervalOptions } from '../../utils/config';
import { saveSearchParamsInStorage } from '../../utils/GetSearchParams';
import timezones from '../../utils/timezone_options';
import ActionsModal from '../ActionsModal/ActionsModal';
import CalendarComponent from '../Calendar/CalendarComponent';
import NodeListSelection from '../NodeListSelection/NodeListSelection';
import ResetFilters from '../ResetFilters/ResetFilters';
import DisplayTagComponent, {
  DisplayTagItem,
  SelectedTag,
} from '../Select/DisplayTagComponent';
import MultiSelect from '../Select/MultiSelect';
import AutoRefreshSwitch from './AutoRefreshSwitch';
import './graphFilter.css';

const GraphFilter4G = () => {
  const { current: today } = useRef(
    set(new Date(), { hours: 23, minutes: 59, seconds: 59 })
  );
  const { current: prevMonth } = useRef(
    set(sub(new Date(), { days: 30 }), { hours: 0, minutes: 0, seconds: 0 })
  );

  const isInternal = isInternalUser();

  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isFetching, isSuccess } = useGetContext();
  const countryData = !isFetching && isSuccess ? data : [];

  const countryCode = searchParams.get('countryCode');
  const providerName = searchParams.get('providerName');
  const timeIntervals = searchParams.get('timeInterval') ?? '86400';
  const selectedNodesString = searchParams.get('selectedNodes') ?? '[]';
  const selectedNodes: { value: string; label: string; id: string }[] =
    JSON.parse(selectedNodesString);

  const plmnsQuery = usePlmnByProviderQueryClient({
    countryCode,
    providerName,
  });

  const nodesQuery = useNodesClient({
    plmns: plmnsQuery.data?.plmns,
  });

  const allowedSelectedNodes =
    nodesQuery.status === 'success'
      ? selectedNodes.map((n) => {
          const nodeIds = Object.keys(nodesQuery.data);
          const foundNode = n.id
            ? nodeIds.includes(n.id)
            : nodeIds.includes(n.value);

          const selected: DisplayTagItem = {
            ...n,
            status: foundNode ? 'active' : 'disabled',
            ...(foundNode
              ? false
              : {
                  disabledMessage:
                    'This node is not available for this Customer',
                }),
          };

          return selected;
        })
      : [];

  const fromDateFromParams = searchParams.get('fromDate');
  const toDateFromParams = searchParams.get('toDate');
  const selectedTimeZone = searchParams.get('timezone');

  useEffect(() => {
    if (isSuccess && countryCode === null) {
      const firstCountryCode = data[0].countryCode;

      setSearchParams((prev) => {
        prev.set('countryCode', firstCountryCode);
        return prev;
      });
    }
  }, [setSearchParams, countryCode, data, isSuccess]);

  useEffect(() => {
    if (isSuccess && providerName === null) {
      const firstProviderName = data[0].providers[0].name;

      setSearchParams((prev) => {
        prev.set('providerName', firstProviderName);
        return prev;
      });
    }
  }, [setSearchParams, providerName, data, isSuccess]);

  const selectedFromDate =
    fromDateFromParams !== null ? new Date(fromDateFromParams) : prevMonth;

  const selectedFromDateZoned = toZonedTime(
    selectedFromDate,
    selectedTimeZone ?? 'Etc/UTC'
  );

  const selectedToDate =
    toDateFromParams !== null ? new Date(toDateFromParams) : today;

  const selectedToDateZoned = toZonedTime(
    selectedToDate,
    selectedTimeZone ?? 'Etc/UTC'
  );

  const dateRangeMs = differenceInMilliseconds(
    selectedToDate,
    selectedFromDate
  );

  const nodeToggleValue = searchParams.get('nodeToggle') ?? 'combine-nodes';

  const country = countryData.find((c) => c.countryCode === countryCode);

  const provider = country
    ? country.providers.find((p) => p.name === providerName)
    : false;
  const brandName: string = country && provider ? provider.brandName : '';

  const selectedTimeInterval = timeIntervalOptions.find((option) => {
    return option.value === timeIntervals;
  });

  useEffect(() => {
    const allowedIntervals = timeIntervalOptions.filter((option) => {
      return dateRangeMs >= option.min && dateRangeMs < option.max;
    });

    if (allowedIntervals.length === 0) {
      setSearchParams((prev) => {
        prev.set('timeInterval', timeIntervalOptions[0].value);
        return prev;
      });
    }

    const intervalInAllowed = allowedIntervals.find(
      (option) => option.value === timeIntervals
    );

    if (allowedIntervals.length > 0 && !intervalInAllowed) {
      setSearchParams((prev) => {
        prev.set('timeInterval', allowedIntervals[0].value);
        return prev;
      });
    }
  }, [timeIntervals, setSearchParams, dateRangeMs]);

  const listItemClicked = (listName: string, option: SelectedTag) => {
    const { label, value: val, id } = option;

    setSearchParams((prev: URLSearchParams) => {
      const upperCaseName =
        listName.charAt(0).toUpperCase() + listName.slice(1);
      const selectedName = prev.get(`selected${upperCaseName}`);
      if (selectedName !== null) {
        const currentValue = { label: label, value: val, id };
        const urlParamName = JSON.parse(selectedName);
        urlParamName.push(currentValue);
        const stringsOfSelectedTags = JSON.stringify(urlParamName);
        if (listName === 'nodes') {
          const prevSelected = prev.get('selectedNodes') ?? '';
          const arrayValueOfPrevSelected = JSON.parse(prevSelected);
          const itemExists = arrayValueOfPrevSelected.filter(
            (obj: { label: string; value: string }) =>
              obj.value === currentValue.value
          );
          if (itemExists.length < 1) {
            prev.set('selectedNodes', stringsOfSelectedTags);
          }
        } else if (listName === 'cells') {
          const prevSelected = prev.get('selectedCells') ?? '';
          const arrayValueOfPrevSelected = JSON.parse(prevSelected);
          const itemExists = arrayValueOfPrevSelected.filter(
            (obj: { label: string; value: string }) =>
              obj.value === currentValue.value
          );
          if (itemExists.length < 1) {
            prev.set('selectedCells', stringsOfSelectedTags);
          }
        }
        return prev;
      } else {
        const selectedNames = [
          {
            label: label,
            value: val,
            id,
          },
        ];
        const initialStringValue = JSON.stringify(selectedNames);
        if (listName === 'nodes') {
          prev.set('selectedNodes', initialStringValue);
        } else if (listName === 'cells') {
          prev.set('selectedCells', initialStringValue);
        }
      }

      return prev;
    });
    saveSearchParamsInStorage();
  };

  const onDelete = (listName: string, itemValue: string) => {
    setSearchParams((prev: URLSearchParams) => {
      const upperCaseName =
        listName.charAt(0).toUpperCase() + listName.slice(1);
      const selectedName = `selected${upperCaseName}`;
      const getValuesOfListName = prev.get(`${selectedName}`) ?? '';
      const arrValues = JSON.parse(getValuesOfListName);

      const deleteValue = arrValues.filter((ele: { value: string }) => {
        return ele.value !== itemValue;
      });

      if (deleteValue.length === 0) {
        prev.delete(selectedName);
      } else {
        prev.set(selectedName, JSON.stringify(deleteValue));
      }
      return prev;
    });
    saveSearchParamsInStorage();
  };

  //DATES

  const fromDateChanged = (date: Date) => {
    const asUTC = fromZonedTime(
      date,
      selectedTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const temp = asUTC.toISOString();
    setSearchParams((prev: URLSearchParams) => {
      prev.set('fromDate', temp);
      return prev;
    });
    saveSearchParamsInStorage();
  };

  const toDateChanged = (date: Date) => {
    const asUTC = fromZonedTime(
      date,
      selectedTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const temp = asUTC.toISOString();
    setSearchParams((prev: URLSearchParams) => {
      prev.set('toDate', temp);
      return prev;
    });
    saveSearchParamsInStorage();
  };

  const fromMaxDate = () => {
    const todayDate = new Date();

    if (selectedToDate > todayDate) {
      return todayDate;
    }
    return selectedToDate;
  };

  const fromDateMaxTime = (timeForSelection: Date): boolean => {
    // This function is run once per possible time choice, with the parameter as the time option
    const now = new Date();

    if (isSameDay(now, timeForSelection)) {
      return isAfter(now, timeForSelection);
    } else {
      return true;
    }
  };

  const toDateMaxTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() > selectedDate.getTime();
  };

  const {
    data: nodesData,
    isSuccess: nodesIsSuccess,
    isFetching: nodesIsFetching,
  } = useNodesByTechnologyClient({
    plmns: plmnsQuery.data?.plmns,
    technologyType: '4G',
  });

  const countryOptions = countryData.map((country) => {
    return {
      label: country.name,
      options: country.providers.map((p) => ({
        label: p.brandName,
        value: {
          provider: p.name,
          country: country.countryCode,
          brandName: p.brandName,
        },
      })),
    };
  });

  const rangeInSeconds = differenceInSeconds(selectedToDate, selectedFromDate);

  useEffect(() => {
    if (selectedTimeZone === null) {
      const storedTimeZone = localStorage.getItem('dw_time_zone');

      if (storedTimeZone) {
        const foundTimeZone = timezones.find((t) => t.value === storedTimeZone);

        if (foundTimeZone) {
          setSearchParams(
            (prev) => {
              prev.set('timezone', foundTimeZone.value);
              return prev;
            },
            { replace: true }
          );
        }
      } else {
        setSearchParams(
          (prev) => {
            prev.set('timezone', 'Etc/UTC');
            return prev;
          },
          { replace: true }
        );
      }
    }
  }, [setSearchParams, selectedTimeZone]);

  return (
    <Box py="2">
      <SimpleGrid
        columns={[1, 1, 2, 4, 6]}
        gridGap={['0.4em', '0.4em', '0.4em', '0.8em', '1em', '0.4em']}
      >
        <Box>
          <CalendarComponent
            name={'From'}
            selectedDate={selectedFromDateZoned}
            dateChangedFn={fromDateChanged}
            minDate={subYears(selectedToDate, 1)}
            maxDate={fromMaxDate()}
            filterTime={fromDateMaxTime}
            helpComponent={
              <Tooltip label="The maximum date range available is one year of data">
                <Icon
                  name="help"
                  color="#666666"
                  fontSize="14px"
                  ml="1"
                  verticalAlign="initial"
                />
              </Tooltip>
            }
          />
          <Box mt="2">
            <AutoRefreshSwitch />
          </Box>
        </Box>
        <Box>
          <CalendarComponent
            name={'To'}
            selectedDate={selectedToDateZoned}
            dateChangedFn={toDateChanged}
            maxDate={new Date()}
            minDate={selectedFromDate}
            filterTime={toDateMaxTime}
            helpComponent={
              <Tooltip label="The maximum date range available is one year of data">
                <Icon
                  name="help"
                  color="#666666"
                  fontSize="14px"
                  ml="1"
                  verticalAlign="initial"
                />
              </Tooltip>
            }
          />
          {rangeInSeconds > 31622399 ? (
            <Box color="red" fontSize="sm">
              The maximum date range is 1 year
            </Box>
          ) : null}
        </Box>
        <Box>
          Time zone
          <Select
            id="select-timezone"
            inputId="select-input-timezone"
            instanceId="select-timezone"
            className="selectStyles"
            styles={{
              option: (baseStyles) => {
                return {
                  ...baseStyles,
                  ':hover': {
                    color: 'white',
                    background: '#37596A',
                  },
                };
              },
            }}
            options={timezones}
            value={timezones.find((tz) => selectedTimeZone === tz.value)}
            onChange={(newValue) => {
              if (newValue) {
                localStorage.setItem('dw_time_zone', newValue.value);
                setSearchParams((prev) => {
                  prev.set('timezone', newValue.value);
                  return prev;
                });
              }
            }}
          />
        </Box>
        <Box>
          Customer
          <Select
            id="select-provider"
            inputId="select-input-provider"
            instanceId="select-provider"
            placeholder={'Provider'}
            className={'selectStyles'}
            styles={{
              option: (baseStyles) => {
                return {
                  ...baseStyles,
                  ':hover': {
                    color: 'white',
                    background: '#37596A',
                  },
                };
              },
            }}
            options={countryOptions}
            onChange={(newV) => {
              if (newV) {
                setSearchParams((prev: URLSearchParams) => {
                  prev.set('countryCode', newV.value.country);
                  prev.set('providerName', newV.value.provider);

                  return prev;
                });
                saveSearchParamsInStorage();
              }
            }}
            getOptionLabel={(v) => {
              return `${v.value.country} - ${v.value.brandName}`;
            }}
            value={
              countryCode && providerName
                ? {
                    label: countryCode,
                    value: {
                      provider: providerName,
                      country: countryCode,
                      brandName: brandName,
                    },
                  }
                : undefined
            }
          />
        </Box>
        <Box position="relative">
          Nodes
          <HStack justifyContent="stretch" alignItems="end">
            {isInternal ? (
              <Box width="100%">
                <MultiSelect
                  listName="nodes"
                  showTitle={false}
                  isLoading={nodesIsFetching}
                  options={
                    nodesIsSuccess
                      ? Object.entries(nodesData)
                          .map(([nodeId, n]) => ({
                            label: n.nodeName,
                            value: n.nodeName,
                            id: nodeId,
                          }))
                          .sort((a, b) => {
                            return a.label.localeCompare(b.label);
                          })
                      : []
                  }
                  onSelect={listItemClicked}
                />
              </Box>
            ) : null}
            <Box width="100%">
              <NodeListSelection showAddItemButton={true} />
            </Box>
          </HStack>
          <RadioGroup
            mt="2"
            value={nodeToggleValue}
            onChange={(newValue) => {
              setSearchParams((prev) => {
                prev.set('nodeToggle', newValue);
                return prev;
              });
            }}
          >
            <Stack>
              <Radio value="per-node" id="node-toggle-per-node">
                Per Node
              </Radio>
              <Radio value="combine-nodes" id="node-toggle-combine-nodes">
                Combine Nodes
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Box display={'grid'} gridTemplateColumns={'1fr 30px'} gridGap={'10px'}>
          <Box>
            Time Interval
            <Select
              id="select-interval"
              inputId="select-input-interval"
              instanceId="select-interval"
              placeholder="Time interval"
              className={'selectStyles'}
              styles={{
                option: (baseStyles) => {
                  return {
                    ...baseStyles,
                    ':hover': {
                      color: 'white',
                      background: '#37596A',
                    },
                  };
                },
              }}
              options={timeIntervalOptions}
              isOptionDisabled={(option) => {
                return dateRangeMs <= option.min || dateRangeMs > option.max;
              }}
              onChange={(val) => {
                if (val !== null && val !== undefined) {
                  const clickedValue = val.value;
                  setSearchParams((prev: URLSearchParams) => {
                    prev.set('timeInterval', clickedValue);
                    return prev;
                  });
                }
                saveSearchParamsInStorage();
              }}
              value={selectedTimeInterval}
            />
          </Box>
          <Box>
            <ResetFilters />
          </Box>
        </Box>
      </SimpleGrid>
      <DisplayTagComponent
        listName="nodes"
        selectedTags={allowedSelectedNodes}
        deleteTagFunc={onDelete}
      />
      {countryCode && providerName && selectedNodes.length > 0 ? (
        <ActionsModal
          nodeNames={selectedNodes}
          plmns={plmnsQuery.data ? plmnsQuery.data.plmns : []}
          dateAndTimeStart={JSON.stringify(selectedFromDate)}
          dateAndTimeEnd={JSON.stringify(selectedToDate)}
          provider={providerName}
          country={countryCode}
          deleteTagFunc={onDelete}
        />
      ) : null}
    </Box>
  );
};

export default GraphFilter4G;
