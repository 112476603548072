import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppDispatch';
import { useGetThresholdLists } from '../../services/userProfileClient';
import {
  ThresholdEditFormState,
  ThresholdFormState,
} from '../../store/modalTabSlice';
import DeleteItem from './DeleteItem';
import EditThresholdsForm from './EditThresholdsForm';
import ThresholdsForm from './ThresholdsForm';

const Thresholds = () => {
  const showForm = useAppSelector(
    (state) => state.modalTabSettings.isThresholdFormOpen
  );
  const showThresholdEditForm = useAppSelector(
    (state) => state.modalTabSettings.thresholdsEditForm.isThresholdEditFormOpen
  );
  const dispatch = useDispatch();

  const { data } = useGetThresholdLists();

  return (
    <Box>
      <Heading
        size={'md'}
        fontWeight={600}
        mb={5}
        borderBottom={'1px solid lightgray'}
        pb={'10px'}
      >
        Threshold Collections
      </Heading>

      {showForm ? (
        <Box>
          <Flex justifyContent={'end'}>
            <Button
              id={'thresholds-back-button'}
              variant={'underLine'}
              onClick={() => dispatch(ThresholdFormState(false))}
            >
              Back
            </Button>
          </Flex>
          <ThresholdsForm />
        </Box>
      ) : showThresholdEditForm ? (
        <Box>
          <Flex justifyContent={'end'}>
            <Button
              id={'edit-thresholds-back-button'}
              variant={'underLine'}
              onClick={() => {
                dispatch(ThresholdFormState(false)),
                  dispatch(
                    ThresholdEditFormState({
                      isThresholdEditFormOpen: false,
                      thresholdEditId: null,
                    })
                  );
              }}
            >
              Back
            </Button>
          </Flex>
          <EditThresholdsForm />
        </Box>
      ) : (
        <Box>
          <Flex mb={5}>
            <Button
              id={'create-thresholds-button'}
              variant={'unstyled'}
              onClick={() => dispatch(ThresholdFormState(true))}
            >
              Create Threshold Collection
            </Button>
          </Flex>
          <Box mt={20} id={'existing-thresholds'}>
            <Heading size={'md'} mb={'20px'} fontWeight={'medium'}>
              Existing Thresholds
            </Heading>
            <VStack alignItems={'left'}>
              {data?.map((threshold) => {
                const thresholdData = JSON.parse(threshold.payload);
                const sectionName = threshold.sectionName;
                const name = thresholdData.listName;
                if (threshold.sectionName === 'Threshold') {
                  return (
                    <Grid
                      id={`threshold-${sectionName}-${name}`}
                      gridTemplateColumns={'1fr 1fr 40px'}
                      key={threshold.userProfileId}
                      background={'primary'}
                      color={'white'}
                      borderRadius={7}
                      alignContent={'center'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'start'}
                        padding={'0 15px'}
                      >
                        <Text pl={3} mr={'10px'}>
                          {name}
                        </Text>
                        <Tag pt={'2px'} fontSize={'10px'}>
                          Collection ID - {threshold.userProfileId}
                        </Tag>
                      </Box>
                      <Box
                        display={'flex'}
                        justifyContent={'end'}
                        alignItems={'center'}
                      >
                        <Button
                          id={`edit-button-${sectionName}-${name}`}
                          padding={'0 15px'}
                          onClick={() =>
                            dispatch(
                              ThresholdEditFormState({
                                isThresholdEditFormOpen: true,
                                thresholdEditId: threshold.userProfileId,
                              })
                            )
                          }
                          borderRadius={'0'}
                        >
                          Edit
                        </Button>
                      </Box>

                      <DeleteItem
                        id={threshold.userProfileId}
                        sectionName={sectionName}
                        itemName={name}
                      />
                    </Grid>
                  );
                }
              })}
            </VStack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Thresholds;
