import { useBoolean } from '@chakra-ui/react';
import { createContext, FC, ReactNode, useContext } from 'react';

type ControlsState = {
  showTrends: boolean;
  showGlobalThresholds: boolean;
  showCalendarAvg: boolean;
  showRollingAverage: boolean;
};
type ControlsActions = {
  toggleShowTrends: () => void;
  toggleShowGlobalThresholds: () => void;
  toggleShowCalendarAvg: () => void;
  hideCalendarAvg: () => void;
  toggleShowRollingAverage: () => void;
  hideRollingAverage: () => void;
};

export const ChartControlsContext = createContext<
  [ControlsState, ControlsActions] | null
>(null);

export const useChartControlsContext = () => {
  const context = useContext(ChartControlsContext);

  if (context === null) {
    throw new Error(
      'useChartControlsContext must be within ChartControlsProvider'
    );
  }

  return context;
};

export const ChartControlsProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const [showTrends, { toggle: toggleShowTrends }] = useBoolean(false);
  const [showGlobalThresholds, { toggle: toggleShowGlobalThresholds }] =
    useBoolean(false);
  const [
    showCalendarAvg,
    { toggle: toggleShowCalendarAvg, off: hideCalendarAvg },
  ] = useBoolean(false);
  const [
    showRollingAverage,
    { toggle: toggleShowRollingAverage, off: hideRollingAverage },
  ] = useBoolean(false);

  return (
    <ChartControlsContext.Provider
      value={[
        {
          showTrends,
          showGlobalThresholds,
          showCalendarAvg,
          showRollingAverage,
        },
        {
          toggleShowTrends,
          toggleShowGlobalThresholds,
          toggleShowCalendarAvg,
          toggleShowRollingAverage,
          hideCalendarAvg,
          hideRollingAverage,
        },
      ]}
    >
      {children}
    </ChartControlsContext.Provider>
  );
};
