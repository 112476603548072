import '@fontsource/poppins';
import '@fontsource/poppins/700.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import { FC, ReactElement } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import HomePage from './pages/HomePage';
import MainErrorPage from './pages/MainErrorPage';
import { AuthProvider } from './utils/AuthProvider';

import annotationPlugin from 'chartjs-plugin-annotation';

const Wrapper: FC<{ children: ReactElement }> = (props) => {
  return props.children;
};

// RQ
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 0,
    },
  },
});

const App = () => {
  const currentUrl = new URL(window.location.href);
  const tokenFromUrl = currentUrl.searchParams.get('token');
  const tokenFromStorage = localStorage.getItem('dw_authToken');

  if (tokenFromStorage === null && tokenFromUrl) {
    localStorage.setItem('dw_authToken', tokenFromUrl);
  }

  if (tokenFromUrl) {
    currentUrl.searchParams.delete('token');
    window.history.replaceState({}, '', currentUrl.toString());
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    TimeSeriesScale,
    annotationPlugin
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Wrapper>
          <AuthProvider />
        </Wrapper>
      ),
      handle: {
        crumbName: 'Home',
      },
      errorElement: <MainErrorPage />,
      children: [
        {
          index: true,
          element: <HomePage />,
        },
        {
          path: 'dashboards/:dashboardId',
          element: <DashboardPage />,
          handle: {
            crumbName: 'Dashboard',
          },
        },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
