import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

export type ListButtonProps = {
  itemId: string;
  title: string;
  isActive?: boolean | undefined;
  onMouseEnter?: MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave?: MouseEventHandler<HTMLDivElement> | undefined;
  rightAddOn?: undefined | JSX.Element;
};

const ListButton: FC<ListButtonProps> = ({
  itemId,
  title,
  onMouseEnter,
  onMouseLeave,
  isActive,
  rightAddOn,
}) => {
  const styles = {
    container: {
      display: 'flex',
      background: isActive ? 'primaryDarkFifteenPercent' : 'primary',
      alignItems: 'center',
      p: '10px',
      color: 'brand.white',
      maxWidth: '600px',
      borderRadius: '5px',
      marginBottom: '10px',
      transition: 'background 0.3s ease',
      _hover: {
        background: 'primaryDarkFifteenPercent',
        cursor: 'pointer',
      },
    },
    icon: {
      mr: '10px',
    },
    title: {
      fontWeight: 400,
    },
    tag: {
      marginRight: 0,
      marginLeft: 'auto',
    },
    id: {
      fontWeight: 400,
    },
  };

  const params = localStorage.getItem('SearchParams') ?? '';

  return (
    <Link
      to={`/dashboards/${itemId}?${params}`}
      id={`dashboard-list-item-${itemId}`}
    >
      <Box
        sx={styles.container}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ArrowForwardIcon sx={styles.icon} />
        <Box sx={styles.title}>{title}</Box>
        {rightAddOn ? <Box sx={styles.tag}>{rightAddOn}</Box> : null}
      </Box>
    </Link>
  );
};

export default ListButton;
