import { Box } from '@chakra-ui/react';
import BreadcrumbComponent from '../Breadcrumb/BreadcrumbComponent';

const Title = () => {
  return (
    <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
      <Box p={'15px'}>
        {/* <Heading
          color={'primary'}
          fontWeight={'700'}
          fontSize={'40px'}
          size={'h1'}
        >
          Desnseware Analytics
        </Heading> */}
        <BreadcrumbComponent />
      </Box>
    </Box>
  );
};

export default Title;
