import { Options } from 'react-select';

const tzs: Options<{ value: string; label: string }> = [
  // UTC + London
  { value: 'Etc/UTC', label: 'UTC' },
  { value: 'Europe/London', label: 'Europe/London' },

  // US Deployed
  { value: 'America/Chicago', label: 'America/Chicago' },
  { value: 'America/Detroit', label: 'America/Detroit' },
  { value: 'America/Los_Angeles', label: 'America/Los Angeles' },
  { value: 'America/New_York', label: 'America/New York' },

  // Rest of US
  { value: 'America/Adak', label: 'America/Adak' },
  { value: 'America/Anchorage', label: 'America/Anchorage' },
  { value: 'America/Boise', label: 'America/Boise' },
  { value: 'America/Denver', label: 'America/Denver' },
  {
    value: 'America/Indiana/Indianapolis',
    label: 'America/Indiana/Indianapolis',
  },
  { value: 'America/Indiana/Knox', label: 'America/Indiana/Knox' },
  { value: 'America/Indiana/Marengo', label: 'America/Indiana/Marengo' },
  {
    value: 'America/Indiana/Petersburg',
    label: 'America/Indiana/Petersburg',
  },
  {
    value: 'America/Indiana/Tell_City',
    label: 'America/Indiana/Tell City',
  },
  { value: 'America/Indiana/Vevay', label: 'America/Indiana/Vevay' },
  {
    value: 'America/Indiana/Vincennes',
    label: 'America/Indiana/Vincennes',
  },
  { value: 'America/Indiana/Winamac', label: 'America/Indiana/Winamac' },
  { value: 'America/Juneau', label: 'America/Juneau' },
  {
    value: 'America/Kentucky/Louisville',
    label: 'America/Kentucky/Louisville',
  },
  {
    value: 'America/Kentucky/Monticello',
    label: 'America/Kentucky/Monticello',
  },
  { value: 'America/Menominee', label: 'America/Menominee' },
  { value: 'America/Metlakatla', label: 'America/Metlakatla' },
  { value: 'America/Nome', label: 'America/Nome' },
  {
    value: 'America/North_Dakota/Beulah',
    label: 'America/North Dakota/Beulah',
  },
  {
    value: 'America/North_Dakota/Center',
    label: 'America/North Dakota/Center',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    label: 'America/North Dakota/New Salem',
  },
  { value: 'America/Phoenix', label: 'America/Phoenix' },
  { value: 'America/Sitka', label: 'America/Sitka' },
  { value: 'America/Yakutat', label: 'America/Yakutat' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },

  // RoW
  { value: 'Africa/Abidjan', label: 'Africa/Abidjan' },
  { value: 'Africa/Algiers', label: 'Africa/Algiers' },
  { value: 'Africa/Bissau', label: 'Africa/Bissau' },
  { value: 'Africa/Cairo', label: 'Africa/Cairo' },
  { value: 'Africa/Casablanca', label: 'Africa/Casablanca' },
  { value: 'Africa/Ceuta', label: 'Africa/Ceuta' },
  { value: 'Africa/El_Aaiun', label: 'Africa/El Aaiun' },
  { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg' },
  { value: 'Africa/Juba', label: 'Africa/Juba' },
  { value: 'Africa/Khartoum', label: 'Africa/Khartoum' },
  { value: 'Africa/Lagos', label: 'Africa/Lagos' },
  { value: 'Africa/Maputo', label: 'Africa/Maputo' },
  { value: 'Africa/Monrovia', label: 'Africa/Monrovia' },
  { value: 'Africa/Nairobi', label: 'Africa/Nairobi' },
  { value: 'Africa/Ndjamena', label: 'Africa/Ndjamena' },
  { value: 'Africa/Sao_Tome', label: 'Africa/Sao Tome' },
  { value: 'Africa/Tripoli', label: 'Africa/Tripoli' },
  { value: 'Africa/Tunis', label: 'Africa/Tunis' },
  { value: 'Africa/Windhoek', label: 'Africa/Windhoek' },
  { value: 'America/Araguaina', label: 'America/Araguaina' },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'America/Argentina/Buenos Aires',
  },
  {
    value: 'America/Argentina/Catamarca',
    label: 'America/Argentina/Catamarca',
  },
  {
    value: 'America/Argentina/Cordoba',
    label: 'America/Argentina/Cordoba',
  },
  { value: 'America/Argentina/Jujuy', label: 'America/Argentina/Jujuy' },
  {
    value: 'America/Argentina/La_Rioja',
    label: 'America/Argentina/La Rioja',
  },
  {
    value: 'America/Argentina/Mendoza',
    label: 'America/Argentina/Mendoza',
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    label: 'America/Argentina/Rio Gallegos',
  },
  { value: 'America/Argentina/Salta', label: 'America/Argentina/Salta' },
  {
    value: 'America/Argentina/San_Juan',
    label: 'America/Argentina/San Juan',
  },
  {
    value: 'America/Argentina/San_Luis',
    label: 'America/Argentina/San Luis',
  },
  {
    value: 'America/Argentina/Tucuman',
    label: 'America/Argentina/Tucuman',
  },
  {
    value: 'America/Argentina/Ushuaia',
    label: 'America/Argentina/Ushuaia',
  },
  { value: 'America/Asuncion', label: 'America/Asuncion' },
  { value: 'America/Bahia', label: 'America/Bahia' },
  { value: 'America/Bahia_Banderas', label: 'America/Bahia Banderas' },
  { value: 'America/Barbados', label: 'America/Barbados' },
  { value: 'America/Belem', label: 'America/Belem' },
  { value: 'America/Belize', label: 'America/Belize' },
  { value: 'America/Boa_Vista', label: 'America/Boa Vista' },
  { value: 'America/Bogota', label: 'America/Bogota' },
  { value: 'America/Cambridge_Bay', label: 'America/Cambridge Bay' },
  { value: 'America/Campo_Grande', label: 'America/Campo Grande' },
  { value: 'America/Cancun', label: 'America/Cancun' },
  { value: 'America/Caracas', label: 'America/Caracas' },
  { value: 'America/Cayenne', label: 'America/Cayenne' },
  { value: 'America/Chihuahua', label: 'America/Chihuahua' },
  { value: 'America/Ciudad_Juarez', label: 'America/Ciudad Juarez' },
  { value: 'America/Costa_Rica', label: 'America/Costa Rica' },
  { value: 'America/Cuiaba', label: 'America/Cuiaba' },
  { value: 'America/Danmarkshavn', label: 'America/Danmarkshavn' },
  { value: 'America/Dawson', label: 'America/Dawson' },
  { value: 'America/Dawson_Creek', label: 'America/Dawson Creek' },
  { value: 'America/Edmonton', label: 'America/Edmonton' },
  { value: 'America/Eirunepe', label: 'America/Eirunepe' },
  { value: 'America/El_Salvador', label: 'America/El Salvador' },
  { value: 'America/Fort_Nelson', label: 'America/Fort Nelson' },
  { value: 'America/Fortaleza', label: 'America/Fortaleza' },
  { value: 'America/Glace_Bay', label: 'America/Glace Bay' },
  { value: 'America/Goose_Bay', label: 'America/Goose Bay' },
  { value: 'America/Grand_Turk', label: 'America/Grand Turk' },
  { value: 'America/Guatemala', label: 'America/Guatemala' },
  { value: 'America/Guayaquil', label: 'America/Guayaquil' },
  { value: 'America/Guyana', label: 'America/Guyana' },
  { value: 'America/Halifax', label: 'America/Halifax' },
  { value: 'America/Havana', label: 'America/Havana' },
  { value: 'America/Hermosillo', label: 'America/Hermosillo' },

  { value: 'America/Inuvik', label: 'America/Inuvik' },
  { value: 'America/Iqaluit', label: 'America/Iqaluit' },
  { value: 'America/Jamaica', label: 'America/Jamaica' },
  { value: 'America/La_Paz', label: 'America/La Paz' },
  { value: 'America/Lima', label: 'America/Lima' },
  { value: 'America/Maceio', label: 'America/Maceio' },
  { value: 'America/Managua', label: 'America/Managua' },
  { value: 'America/Manaus', label: 'America/Manaus' },
  { value: 'America/Martinique', label: 'America/Martinique' },
  { value: 'America/Matamoros', label: 'America/Matamoros' },
  { value: 'America/Mazatlan', label: 'America/Mazatlan' },
  { value: 'America/Merida', label: 'America/Merida' },
  { value: 'America/Mexico_City', label: 'America/Mexico City' },
  { value: 'America/Miquelon', label: 'America/Miquelon' },
  { value: 'America/Moncton', label: 'America/Moncton' },
  { value: 'America/Monterrey', label: 'America/Monterrey' },
  { value: 'America/Montevideo', label: 'America/Montevideo' },
  { value: 'America/Noronha', label: 'America/Noronha' },
  { value: 'America/Nuuk', label: 'America/Nuuk' },
  { value: 'America/Ojinaga', label: 'America/Ojinaga' },
  { value: 'America/Panama', label: 'America/Panama' },
  { value: 'America/Paramaribo', label: 'America/Paramaribo' },
  { value: 'America/Port-au-Prince', label: 'America/Port-au-Prince' },
  { value: 'America/Porto_Velho', label: 'America/Porto Velho' },
  { value: 'America/Puerto_Rico', label: 'America/Puerto Rico' },
  { value: 'America/Punta_Arenas', label: 'America/Punta Arenas' },
  { value: 'America/Rankin_Inlet', label: 'America/Rankin Inlet' },
  { value: 'America/Recife', label: 'America/Recife' },
  { value: 'America/Regina', label: 'America/Regina' },
  { value: 'America/Resolute', label: 'America/Resolute' },
  { value: 'America/Rio_Branco', label: 'America/Rio Branco' },
  { value: 'America/Santarem', label: 'America/Santarem' },
  { value: 'America/Santiago', label: 'America/Santiago' },
  { value: 'America/Santo_Domingo', label: 'America/Santo Domingo' },
  { value: 'America/Sao_Paulo', label: 'America/Sao Paulo' },
  { value: 'America/Scoresbysund', label: 'America/Scoresbysund' },
  { value: 'America/St_Johns', label: 'America/St Johns' },
  { value: 'America/Swift_Current', label: 'America/Swift Current' },
  { value: 'America/Tegucigalpa', label: 'America/Tegucigalpa' },
  { value: 'America/Thule', label: 'America/Thule' },
  { value: 'America/Tijuana', label: 'America/Tijuana' },
  { value: 'America/Toronto', label: 'America/Toronto' },
  { value: 'America/Vancouver', label: 'America/Vancouver' },
  { value: 'America/Whitehorse', label: 'America/Whitehorse' },
  { value: 'America/Winnipeg', label: 'America/Winnipeg' },
  { value: 'Antarctica/Casey', label: 'Antarctica/Casey' },
  { value: 'Antarctica/Davis', label: 'Antarctica/Davis' },
  { value: 'Antarctica/Macquarie', label: 'Antarctica/Macquarie' },
  { value: 'Antarctica/Mawson', label: 'Antarctica/Mawson' },
  { value: 'Antarctica/Palmer', label: 'Antarctica/Palmer' },
  { value: 'Antarctica/Rothera', label: 'Antarctica/Rothera' },
  { value: 'Antarctica/Troll', label: 'Antarctica/Troll' },
  { value: 'Antarctica/Vostok', label: 'Antarctica/Vostok' },
  { value: 'Asia/Almaty', label: 'Asia/Almaty' },
  { value: 'Asia/Amman', label: 'Asia/Amman' },
  { value: 'Asia/Anadyr', label: 'Asia/Anadyr' },
  { value: 'Asia/Aqtau', label: 'Asia/Aqtau' },
  { value: 'Asia/Aqtobe', label: 'Asia/Aqtobe' },
  { value: 'Asia/Ashgabat', label: 'Asia/Ashgabat' },
  { value: 'Asia/Atyrau', label: 'Asia/Atyrau' },
  { value: 'Asia/Baghdad', label: 'Asia/Baghdad' },
  { value: 'Asia/Baku', label: 'Asia/Baku' },
  { value: 'Asia/Bangkok', label: 'Asia/Bangkok' },
  { value: 'Asia/Barnaul', label: 'Asia/Barnaul' },
  { value: 'Asia/Beirut', label: 'Asia/Beirut' },
  { value: 'Asia/Bishkek', label: 'Asia/Bishkek' },
  { value: 'Asia/Chita', label: 'Asia/Chita' },
  { value: 'Asia/Choibalsan', label: 'Asia/Choibalsan' },
  { value: 'Asia/Colombo', label: 'Asia/Colombo' },
  { value: 'Asia/Damascus', label: 'Asia/Damascus' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka' },
  { value: 'Asia/Dili', label: 'Asia/Dili' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai' },
  { value: 'Asia/Dushanbe', label: 'Asia/Dushanbe' },
  { value: 'Asia/Famagusta', label: 'Asia/Famagusta' },
  { value: 'Asia/Gaza', label: 'Asia/Gaza' },
  { value: 'Asia/Hebron', label: 'Asia/Hebron' },
  { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho Chi Minh' },
  { value: 'Asia/Hong_Kong', label: 'Asia/Hong Kong' },
  { value: 'Asia/Hovd', label: 'Asia/Hovd' },
  { value: 'Asia/Irkutsk', label: 'Asia/Irkutsk' },
  { value: 'Asia/Jakarta', label: 'Asia/Jakarta' },
  { value: 'Asia/Jayapura', label: 'Asia/Jayapura' },
  { value: 'Asia/Jerusalem', label: 'Asia/Jerusalem' },
  { value: 'Asia/Kabul', label: 'Asia/Kabul' },
  { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka' },
  { value: 'Asia/Karachi', label: 'Asia/Karachi' },
  { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu' },
  { value: 'Asia/Khandyga', label: 'Asia/Khandyga' },
  { value: 'Asia/Kolkata', label: 'Asia/Kolkata' },
  { value: 'Asia/Krasnoyarsk', label: 'Asia/Krasnoyarsk' },
  { value: 'Asia/Kuching', label: 'Asia/Kuching' },
  { value: 'Asia/Macau', label: 'Asia/Macau' },
  { value: 'Asia/Magadan', label: 'Asia/Magadan' },
  { value: 'Asia/Makassar', label: 'Asia/Makassar' },
  { value: 'Asia/Manila', label: 'Asia/Manila' },
  { value: 'Asia/Nicosia', label: 'Asia/Nicosia' },
  { value: 'Asia/Novokuznetsk', label: 'Asia/Novokuznetsk' },
  { value: 'Asia/Novosibirsk', label: 'Asia/Novosibirsk' },
  { value: 'Asia/Omsk', label: 'Asia/Omsk' },
  { value: 'Asia/Oral', label: 'Asia/Oral' },
  { value: 'Asia/Pontianak', label: 'Asia/Pontianak' },
  { value: 'Asia/Pyongyang', label: 'Asia/Pyongyang' },
  { value: 'Asia/Qatar', label: 'Asia/Qatar' },
  { value: 'Asia/Qostanay', label: 'Asia/Qostanay' },
  { value: 'Asia/Qyzylorda', label: 'Asia/Qyzylorda' },
  { value: 'Asia/Riyadh', label: 'Asia/Riyadh' },
  { value: 'Asia/Sakhalin', label: 'Asia/Sakhalin' },
  { value: 'Asia/Samarkand', label: 'Asia/Samarkand' },
  { value: 'Asia/Seoul', label: 'Asia/Seoul' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore' },
  { value: 'Asia/Srednekolymsk', label: 'Asia/Srednekolymsk' },
  { value: 'Asia/Taipei', label: 'Asia/Taipei' },
  { value: 'Asia/Tashkent', label: 'Asia/Tashkent' },
  { value: 'Asia/Tbilisi', label: 'Asia/Tbilisi' },
  { value: 'Asia/Tehran', label: 'Asia/Tehran' },
  { value: 'Asia/Thimphu', label: 'Asia/Thimphu' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo' },
  { value: 'Asia/Tomsk', label: 'Asia/Tomsk' },
  { value: 'Asia/Ulaanbaatar', label: 'Asia/Ulaanbaatar' },
  { value: 'Asia/Urumqi', label: 'Asia/Urumqi' },
  { value: 'Asia/Ust-Nera', label: 'Asia/Ust-Nera' },
  { value: 'Asia/Vladivostok', label: 'Asia/Vladivostok' },
  { value: 'Asia/Yakutsk', label: 'Asia/Yakutsk' },
  { value: 'Asia/Yangon', label: 'Asia/Yangon' },
  { value: 'Asia/Yekaterinburg', label: 'Asia/Yekaterinburg' },
  { value: 'Asia/Yerevan', label: 'Asia/Yerevan' },
  { value: 'Atlantic/Azores', label: 'Atlantic/Azores' },
  { value: 'Atlantic/Bermuda', label: 'Atlantic/Bermuda' },
  { value: 'Atlantic/Canary', label: 'Atlantic/Canary' },
  { value: 'Atlantic/Cape_Verde', label: 'Atlantic/Cape Verde' },
  { value: 'Atlantic/Faroe', label: 'Atlantic/Faroe' },
  { value: 'Atlantic/Madeira', label: 'Atlantic/Madeira' },
  { value: 'Atlantic/South_Georgia', label: 'Atlantic/South Georgia' },
  { value: 'Atlantic/Stanley', label: 'Atlantic/Stanley' },
  { value: 'Australia/Adelaide', label: 'Australia/Adelaide' },
  { value: 'Australia/Brisbane', label: 'Australia/Brisbane' },
  { value: 'Australia/Broken_Hill', label: 'Australia/Broken Hill' },
  { value: 'Australia/Darwin', label: 'Australia/Darwin' },
  { value: 'Australia/Eucla', label: 'Australia/Eucla' },
  { value: 'Australia/Hobart', label: 'Australia/Hobart' },
  { value: 'Australia/Lindeman', label: 'Australia/Lindeman' },
  { value: 'Australia/Lord_Howe', label: 'Australia/Lord Howe' },
  { value: 'Australia/Melbourne', label: 'Australia/Melbourne' },
  { value: 'Australia/Perth', label: 'Australia/Perth' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney' },
  { value: 'Europe/Andorra', label: 'Europe/Andorra' },
  { value: 'Europe/Astrakhan', label: 'Europe/Astrakhan' },
  { value: 'Europe/Athens', label: 'Europe/Athens' },
  { value: 'Europe/Belgrade', label: 'Europe/Belgrade' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin' },
  { value: 'Europe/Brussels', label: 'Europe/Brussels' },
  { value: 'Europe/Bucharest', label: 'Europe/Bucharest' },
  { value: 'Europe/Budapest', label: 'Europe/Budapest' },
  { value: 'Europe/Chisinau', label: 'Europe/Chisinau' },
  { value: 'Europe/Dublin', label: 'Europe/Dublin' },
  { value: 'Europe/Gibraltar', label: 'Europe/Gibraltar' },
  { value: 'Europe/Helsinki', label: 'Europe/Helsinki' },
  { value: 'Europe/Istanbul', label: 'Europe/Istanbul' },
  { value: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad' },
  { value: 'Europe/Kirov', label: 'Europe/Kirov' },
  { value: 'Europe/Kyiv', label: 'Europe/Kyiv' },
  { value: 'Europe/Lisbon', label: 'Europe/Lisbon' },
  { value: 'Europe/Madrid', label: 'Europe/Madrid' },
  { value: 'Europe/Malta', label: 'Europe/Malta' },
  { value: 'Europe/Minsk', label: 'Europe/Minsk' },
  { value: 'Europe/Moscow', label: 'Europe/Moscow' },
  { value: 'Europe/Paris', label: 'Europe/Paris' },
  { value: 'Europe/Prague', label: 'Europe/Prague' },
  { value: 'Europe/Riga', label: 'Europe/Riga' },
  { value: 'Europe/Rome', label: 'Europe/Rome' },
  { value: 'Europe/Samara', label: 'Europe/Samara' },
  { value: 'Europe/Saratov', label: 'Europe/Saratov' },
  { value: 'Europe/Simferopol', label: 'Europe/Simferopol' },
  { value: 'Europe/Sofia', label: 'Europe/Sofia' },
  { value: 'Europe/Tallinn', label: 'Europe/Tallinn' },
  { value: 'Europe/Tirane', label: 'Europe/Tirane' },
  { value: 'Europe/Ulyanovsk', label: 'Europe/Ulyanovsk' },
  { value: 'Europe/Vienna', label: 'Europe/Vienna' },
  { value: 'Europe/Vilnius', label: 'Europe/Vilnius' },
  { value: 'Europe/Volgograd', label: 'Europe/Volgograd' },
  { value: 'Europe/Warsaw', label: 'Europe/Warsaw' },
  { value: 'Europe/Zurich', label: 'Europe/Zurich' },
  { value: 'Indian/Chagos', label: 'Indian/Chagos' },
  { value: 'Indian/Maldives', label: 'Indian/Maldives' },
  { value: 'Indian/Mauritius', label: 'Indian/Mauritius' },
  { value: 'Pacific/Apia', label: 'Pacific/Apia' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland' },
  { value: 'Pacific/Bougainville', label: 'Pacific/Bougainville' },
  { value: 'Pacific/Chatham', label: 'Pacific/Chatham' },
  { value: 'Pacific/Easter', label: 'Pacific/Easter' },
  { value: 'Pacific/Efate', label: 'Pacific/Efate' },
  { value: 'Pacific/Fakaofo', label: 'Pacific/Fakaofo' },
  { value: 'Pacific/Fiji', label: 'Pacific/Fiji' },
  { value: 'Pacific/Galapagos', label: 'Pacific/Galapagos' },
  { value: 'Pacific/Gambier', label: 'Pacific/Gambier' },
  { value: 'Pacific/Guadalcanal', label: 'Pacific/Guadalcanal' },
  { value: 'Pacific/Guam', label: 'Pacific/Guam' },
  { value: 'Pacific/Kanton', label: 'Pacific/Kanton' },
  { value: 'Pacific/Kiritimati', label: 'Pacific/Kiritimati' },
  { value: 'Pacific/Kosrae', label: 'Pacific/Kosrae' },
  { value: 'Pacific/Kwajalein', label: 'Pacific/Kwajalein' },
  { value: 'Pacific/Marquesas', label: 'Pacific/Marquesas' },
  { value: 'Pacific/Nauru', label: 'Pacific/Nauru' },
  { value: 'Pacific/Niue', label: 'Pacific/Niue' },
  { value: 'Pacific/Norfolk', label: 'Pacific/Norfolk' },
  { value: 'Pacific/Noumea', label: 'Pacific/Noumea' },
  { value: 'Pacific/Pago_Pago', label: 'Pacific/Pago Pago' },
  { value: 'Pacific/Palau', label: 'Pacific/Palau' },
  { value: 'Pacific/Pitcairn', label: 'Pacific/Pitcairn' },
  { value: 'Pacific/Port_Moresby', label: 'Pacific/Port Moresby' },
  { value: 'Pacific/Rarotonga', label: 'Pacific/Rarotonga' },
  { value: 'Pacific/Tahiti', label: 'Pacific/Tahiti' },
  { value: 'Pacific/Tarawa', label: 'Pacific/Tarawa' },
  { value: 'Pacific/Tongatapu', label: 'Pacific/Tongatapu' },
];

export default tzs;
