import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  Heading,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';
import Select from 'react-select';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppDispatch';
import { useGetMeasuresListClient } from '../../services/measureClient';
import {
  checkListOfKpisIsValid,
  useGetThresholdLists,
  useUpdateUserThresholdList,
} from '../../services/userProfileClient';
import { ThresholdEditFormState } from '../../store/modalTabSlice';
import { InProgressKpi, KPIValue, ThresholdListValues } from './ThresholdsForm';

const EditThresholdsForm: FC = () => {
  const [listName, setListName] = useState<string>('');
  const [kpiId, setKpiId] = useState<string | null>(null);
  const { register, handleSubmit } = useForm<KPIValue>({
    defaultValues: {
      name: listName,
    },
  });

  const updateThresholdList = useUpdateUserThresholdList();

  const toast = useToast();
  const [thresholdValue, setThresholdValue] = useState<string>('');
  const isValueANumber =
    thresholdValue === ''
      ? { success: false }
      : z.coerce.number().safeParse(thresholdValue);
  let titleOptions: { label: string; value: string }[] = [];
  const [addedKPIList, setAddedKPIList] = useState<InProgressKpi[]>([]);
  const [showValueInput, setShowValueInput] = useState<boolean>();
  const isValidList =
    listName !== '' && addedKPIList.length > 0 && listName.trim().length !== 0;
  const [showValueIndex, setShowValueIndex] = useState<number>();

  const [thresholdColor, setThresholdColor] = useState<{
    label: string | null;
    value: string | null;
  }>({ label: '', value: '' });

  const addedData: InProgressKpi[] = [...addedKPIList];

  const id = useAppSelector(
    (state) => state.modalTabSettings.thresholdsEditForm.thresholdEditId
  );

  const dispatch = useAppDispatch();

  const { data: thresholdListData, refetch: refetchList } =
    useGetThresholdLists();

  const { data, status } = useGetMeasuresListClient();

  const KPIName = kpiId && data ? data.data.data[kpiId].title : null;

  if (status === 'success') {
    const titles = Object.entries(data.data.data).map((item) => {
      const option = {
        label: item[1].title,
        value: item[0],
      };
      return option;
    });
    titleOptions = titles.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }

  let colorOptions: { label: string; value: string }[] = [];

  colorOptions = [
    { label: 'Red', value: '#700202' },
    { label: 'Green', value: '#255325' },
    { label: 'Yellow', value: '#dab600' },
  ];

  const profileData = thresholdListData?.find((profile) => {
    return profile.userProfileId === id;
  });

  const profilePayload = profileData?.payload;
  const listData: ThresholdListValues = useMemo(
    () => {
      if (profilePayload) {
        return JSON.parse(profilePayload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileData?.userProfileId]
  );

  useEffect(() => {
    if (listData) {
      setListName(listData.listName);
      setAddedKPIList(listData.values);
    }
  }, [listData]);

  const onSubmit = (data: InProgressKpi) => {
    const addedKpi = {
      name: KPIName,
      id: kpiId,
      value: data.value,
      color: thresholdColor.value,
    };

    addedData.push(addedKpi);
    setAddedKPIList(addedData);
    setThresholdValue('');
    setKpiId('');
  };

  const onDeleteKPI = (index: number | null) => {
    setAddedKPIList(
      addedKPIList.filter((ele, i) => {
        return i !== index;
      })
    );
  };

  const changeValue = (index: number) => {
    setShowValueIndex(index);
    setShowValueInput(!showValueInput);
  };

  const handleValueChange = (newValue: number, index: number | null) => {
    const newList = addedKPIList.map((item, i) => {
      if (i === index) {
        item.value = newValue;
      }
      return item;
    });
    setAddedKPIList(newList);
  };

  const handleColorChange = (newColor: string, index: number | null) => {
    const newList = addedKPIList.map((item, i) => {
      if (i === index) {
        item.color = newColor;
      }
      return item;
    });
    setAddedKPIList(newList);
  };

  const isFormValid = checkListOfKpisIsValid(addedKPIList);

  const onThresholdUpdate = () => {
    const otherCollections = thresholdListData?.filter((item) => {
      if (item.sectionName == 'Threshold') {
        return item.userProfileId !== id;
      }
    });

    const listNameExists = otherCollections?.find((item) => {
      const payload = JSON.parse(item.payload);
      const newListName: string = payload.listName;
      if (newListName == listName) {
        return listName;
      }
      return null;
    });

    if (isFormValid) {
      const data: ThresholdListValues = {
        listName: listName,
        values: addedKPIList,
      };

      if (id && listNameExists === undefined) {
        updateThresholdList.mutate(
          { id, data },
          {
            onSuccess: () => {
              refetchList();
              toast({
                title: 'Your list is updated',
                description: '',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top',
              });
              dispatch(
                ThresholdEditFormState({
                  isThresholdEditFormOpen: false,
                  thresholdEditId: null,
                })
              );
            },
          }
        );
      } else if (listNameExists) {
        toast({
          title: 'List Name already exists',
          description: 'Please change your List Name',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        return null;
      }
    }
  };

  return (
    <>
      <Grid gridTemplateColumns={'1fr 1fr'} gridGap={10} minH={'450px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={'20px'}>
            <Input
              id={'thresholds-list-name-input-field'}
              placeholder="Threshold List Name"
              onChange={(e) => {
                const inputValue = e.target.value;
                setListName(inputValue.trim());
              }}
              defaultValue={listName}
            />
          </Box>
          <Box mb={'20px'}>
            <Select
              id={'thresholds-select-dropdown'}
              options={titleOptions}
              onChange={(newValue) => {
                if (newValue !== null) {
                  if (newValue.value) {
                    setKpiId(newValue.value);
                  }
                } else {
                  setKpiId(null);
                }
              }}
              isClearable
              value={kpiId ? { label: KPIName, value: kpiId } : null}
            />
          </Box>
          <Box mb={'20px'}>
            <Input
              id={'add-threshold-value-input-field'}
              placeholder="Add Threshold Value"
              {...register('value')}
              value={thresholdValue}
              onChange={(e) => setThresholdValue(e.target.value)}
            />
          </Box>
          <Box>
            <Select
              id={'color-options-select-dropdown'}
              options={colorOptions}
              placeholder={'Threshold line color'}
              onChange={(color) => {
                if (color !== null) {
                  setThresholdColor({
                    label: color.label,
                    value: color.value,
                  });
                } else {
                  setThresholdColor({
                    label: null,
                    value: null,
                  });
                }
              }}
              isClearable
              value={
                thresholdColor.label
                  ? { label: thresholdColor.label, value: thresholdColor.value }
                  : null
              }
            />
          </Box>
          <Flex>
            <Button
              id={'add-threshold-value-button'}
              type="submit"
              mt={'10px'}
              isDisabled={isValueANumber.success === false}
            >
              Add threshold
            </Button>
          </Flex>
        </form>

        {listName !== '' || addedKPIList.length > 0 ? (
          <Box>
            <Card p={2} maxH={'250px'}>
              <CardHeader>
                <Heading size={'sm'} textTransform={'uppercase'}>
                  {listName}
                </Heading>
              </CardHeader>
              <CardBody overflow={'hidden'} overflowY={'scroll'}>
                {addedKPIList.map((value, index) => {
                  return (
                    <Grid
                      key={index}
                      gridTemplateColumns={'25px 50px 300px 1fr 1fr 1fr'}
                      background={'primary'}
                      color={'white'}
                      borderRadius={7}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      mb={1.5}
                      minH={'50px'}
                    >
                      <Box
                        ml={'10px'}
                        mr={'10px'}
                        width={'20px'}
                        height={'20px'}
                        borderRadius={'5px'}
                        background={value.color ? value.color : 'primary'}
                      ></Box>

                      <Menu>
                        <Button
                          as={MenuButton}
                          p={'0px'}
                          m={'0px'}
                          width={'10px'}
                          ml={'10px'}
                        >
                          <ChevronDownIcon p={'0px'} m={'0px'} />
                        </Button>
                        <MenuList>
                          <MenuItem
                            color={'black'}
                            onClick={() => {
                              handleColorChange('#700202', index);
                            }}
                          >
                            Red
                          </MenuItem>

                          <MenuItem
                            color={'black'}
                            onClick={() => {
                              handleColorChange('#255325', index);
                            }}
                          >
                            Green
                          </MenuItem>

                          <MenuItem
                            color={'black'}
                            onClick={() => {
                              handleColorChange('#dab600', index);
                            }}
                          >
                            Yellow
                          </MenuItem>
                        </MenuList>
                      </Menu>

                      <Text pl={3}>{value.name}</Text>
                      <Button
                        borderRadius={0}
                        p={'10px'}
                        onClick={() => changeValue(index)}
                      >
                        {showValueInput && showValueIndex === index ? (
                          <Icon as={AiOutlineCheck} />
                        ) : (
                          <Icon as={AiOutlineEdit} />
                        )}
                      </Button>
                      {showValueInput && showValueIndex === index ? (
                        <Input
                          type={'text'}
                          borderRadius={0}
                          background={'white'}
                          color={'black'}
                          width={'40px'}
                          pl={2}
                          pr={2}
                          onChange={(e) => {
                            handleValueChange(
                              JSON.parse(e.target.value),
                              index
                            );
                          }}
                        />
                      ) : (
                        <Text key={index} p={'0 10px'}>
                          {value.value}
                        </Text>
                      )}
                      <Button
                        pl={'10px'}
                        borderLeft={'1px solid white'}
                        borderLeftRadius={'none'}
                        p={0}
                        onClick={() => onDeleteKPI(index)}
                      >
                        <Icon as={AiOutlineClose} color={'white'} />
                      </Button>
                    </Grid>
                  );
                })}
              </CardBody>
            </Card>
          </Box>
        ) : null}
      </Grid>
      <Flex justifyContent={'end'} gap={'20px'}>
        <Button
          variant={'unstyled'}
          onClick={() =>
            dispatch(
              ThresholdEditFormState({
                isThresholdEditFormOpen: false,
                thresholdEditId: null,
              })
            )
          }
        >
          Cancel
        </Button>
        <Button onClick={onThresholdUpdate} isDisabled={!isValidList}>
          Update
        </Button>
      </Flex>
    </>
  );
};

export default EditThresholdsForm;
