import { AnnotationOptions } from 'chartjs-plugin-annotation';
import LinearLeastSquares from './LeastSquares';

const calculateTrends = (chartData: { x: number; y: number | null }[][]) =>
  //: Array<{ x1: number; y1: number; x2: number; y2: number; color: string }>
  {
    const trends = chartData.reduce((acc: AnnotationOptions[], data, index) => {
      if (data.length > 1) {
        const clonedData = [...data].sort((a, b) => {
          return a.x - b.x;
        });

        const bestFit = new LinearLeastSquares(
          clonedData
            .filter((d): d is { x: number; y: number } => d.y !== null)
            .map((d) => {
              const x = d.x;
              const y = d.y;
              return [x, y];
            })
        );
        const bestFitResults = bestFit.compute_fit();

        const { m, b } = bestFitResults;

        const x1 = clonedData[0].x;
        const len = clonedData.length;
        const x2 = clonedData[len - 1].x;

        const y1 = m * x1 + b;
        const y2 = m * x2 + b;

        const trendAnnotation: AnnotationOptions = {
          id: `${index}-box`,
          type: 'line',
          xMin: x1,
          xMax: x2,
          yMin: y1,
          yMax: y2,

          // borderColor(ctx, options) {
          //   ctx.element.
          //   return 'green';
          // },
        };

        acc.push(trendAnnotation);
      }

      return acc;
    }, []);

    return trends;
  };

export default calculateTrends;
