import { Button } from '@chakra-ui/react';
import { FC } from 'react';
import { TbWorldSearch } from 'react-icons/tb';
import { MdMonitorHeart } from 'react-icons/md';
import { Link } from 'react-router-dom';

type LinkButtonOptions = {
  countryCode: string;
  providerName: string;
  longitude?: number | null | undefined;
  latitude?: number | null | undefined;
  name?: string;
  isDisabled: boolean;
  nodeName?: string;
  fromDate: string;
};

const LinkButton: FC<LinkButtonOptions> = ({
  countryCode,
  providerName,
  longitude,
  latitude,
  name,
  isDisabled,
  nodeName,
  fromDate,
}) => {
  const country = encodeURIComponent(countryCode);
  const provide = encodeURIComponent(providerName);

  if (name === 'G') {
    const link = `${
      import.meta.env.VITE_APP_GEO
    }#countryCode=${country}&providerName=${provide}&viewState={"latitude":${latitude},"longitude":${longitude},"zoom":16,"pitch":0,"bearing":0,"padding":{"top":0,"bottom":0,"left":0,"right":0},"viewport": [[0,0],[0,0]]}&layers=["oss"]`;

    return (
      <Link to={`${link}`}>
        <Button size="sm" p={'1px'} isDisabled={isDisabled}>
          <TbWorldSearch size="1.4em" />
        </Button>
      </Link>
    );
  } else if (name === 'M' && nodeName) {
    const encodedDate = JSON.parse(fromDate);

    const encodedNode = JSON.stringify(nodeName);
    const link = `${
      import.meta.env.VITE_APP_MONITOR
    }events?from=${encodedDate}&nodes=[${encodedNode}]`;

    return (
      <Link to={`${link}`}>
        <Button size="sm" p={'1px'} isDisabled={isDisabled}>
          <MdMonitorHeart size="1.4em" />
        </Button>
      </Link>
    );
  }
  return null;
};

export default LinkButton;
