import { useQuery } from '@tanstack/react-query';
import { DashboardType } from '../utils/denseware-client-js/client/types';
import denseairClient from './denseairClient';

type GetDashboardsClientOptions = {
  dashboardType: Array<DashboardType>;
};

const useDashQueryClient = (options: GetDashboardsClientOptions) => {
  const { dashboardType } = options;

  return useQuery({
    queryKey: ['dashItemList'],
    queryFn: () =>
      denseairClient.metadata.getDashboards({ params: { dashboardType } }),
  });
};

export default useDashQueryClient;
