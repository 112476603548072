import { Grid, GridItem } from '@chakra-ui/layout';
import {
  Button,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';

import { FC, useRef } from 'react';
import { MdOutlineApps } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import {
  checkApplicationAccess,
  isInternalUser,
} from '../../utils/AuthProvider';
import Profile from '../Profile/Profile';

const Header: FC = () => {
  const { current: portalLink } = useRef({
    buttonId: 'navigate-to-portal',
    title: 'Portal',
    url: import.meta.env.VITE_APP_PORTAL_URL,
  });

  const { current: navList } = useRef([
    {
      buttonId: 'navigate-to-monitor',
      title: 'Monitor',
      url: import.meta.env.VITE_APP_MONITOR_URL,
      isInternalAccess: true,
      role: 'System-Monitor',
    },
    {
      buttonId: 'navigate-to-geo',
      title: 'Geo',
      url: import.meta.env.VITE_APP_GEO_URL,
      isInternalAccess: true,
      role: 'System-Geo',
    },
    {
      buttonId: 'navigate-to-admin',
      title: 'Admin',
      url: import.meta.env.VITE_APP_ADMIN_URL,
      role: 'SystemAdmin',
    },
  ]);

  return (
    <Grid
      className="header"
      id="header"
      templateColumns={'1fr 1fr'}
      p={'0.8% 0.5% 0.8% 1.5%'}
      verticalAlign={'middle'}
      background={'primary'}
    >
      <GridItem>
        <Image
          src={Logo}
          alt="Denseware Logo"
          display="inline"
          verticalAlign={'middle'}
          height={'42px'}
        />
      </GridItem>

      {/* For Navigation use  */}

      {/*<GridItem colStart={3} color={'white'} pt={'7px'}>
       Navigation component 
         <Button
          background={'primary'}
          _hover={{ bg: 'primaryLightFifteenPercent' }}
          _active={{ bg: 'primaryLightFifteenPercent' }}
        >
        </Button> 
      </GridItem>*/}
      <GridItem
        colEnd={6}
        className="navpanel"
        id="user-info-nav"
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
      >
        <Menu id={'nav-to-apps'}>
          <Button
            p={0}
            as={MenuButton}
            variant={'solid'}
            id={'nav-to-apps'}
            mr={'15px'}
            alignItems={'center'}
          >
            <Icon as={MdOutlineApps} color={'white'} boxSize={7} />
          </Button>
          <MenuList>
            <Link
              to={portalLink.url}
              key={portalLink.buttonId}
              id={`${portalLink.buttonId}-link`}
            >
              <Button
                id={`${portalLink.buttonId}-button`}
                variant={'unstyledLink'}
              >
                {portalLink.title}
              </Button>
            </Link>
            {navList
              .filter((f) => {
                if (f.isInternalAccess) {
                  if (isInternalUser()) {
                    return true;
                  }
                }

                return checkApplicationAccess(f.role);
              })
              .map((app) => (
                <Link
                  to={app.url}
                  key={app.buttonId}
                  id={`${app.buttonId}-link`}
                >
                  <Button
                    id={`${app.buttonId}-button`}
                    variant={'unstyledLink'}
                  >
                    {app.title}
                  </Button>
                </Link>
              ))}
          </MenuList>
        </Menu>
        <Profile />
      </GridItem>
    </Grid>
  );
};

export default Header;
