import { Box, Button, Center, Spinner } from '@chakra-ui/react';
import { FC } from 'react';
import useDashBoardData from '../../services/dashBoardChartsClient';
import GraphFilter4G from './GraphFilter4G';
import GraphFilter5G from './GraphFilter5G';

type GraphFilterChoiceProps = { dashboardId: string };

const GraphFilterChoice: FC<GraphFilterChoiceProps> = (props) => {
  const { dashboardId } = props;
  const {
    data: dashboardData,
    status: dashboardResponseStatus,
    refetch,
  } = useDashBoardData(dashboardId);

  if (dashboardResponseStatus === 'success') {
    if (dashboardData.data.data.dashboardType === '5G') {
      return <GraphFilter5G />;
    }
    return <GraphFilter4G />;
  }

  if (dashboardResponseStatus === 'loading') {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box textAlign="center">
      <Box>Error fetching dashboard data</Box>
      <Button size="sm" onClick={() => refetch()}>
        Retry
      </Button>
    </Box>
  );
};

export default GraphFilterChoice;
