import { Box, Button, Card, Flex } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import TagComponent from './TagComponent';

export type SelectedTag = {
  label: string;
  value: string;
  id: string;
};

export type DisplayTagItem = {
  label: string;
  value: string;
  id: string;
  status?: 'active' | 'disabled';
  disabledMessage?: string;
};

export type TagProps = {
  listName: string;
  selectedTags: DisplayTagItem[];
  deleteTagFunc: (id: string, item: string) => void;
};

const DisplayTagComponent: FC<TagProps> = ({
  listName,
  selectedTags,
  deleteTagFunc,
}) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchParams, setSearchParams] = useSearchParams();
  const sortedTags = useMemo(() => {
    return [...selectedTags].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }, [selectedTags]);

  if (selectedTags.length === 0) {
    return null;
  }

  return (
    <Card
      borderColor="brand.darkBlue"
      borderStyle="solid"
      borderWidth="1px"
      px="3"
      py="4"
      mt="3"
    >
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Box pr={'10px'}>Selected {listName}</Box>
        <Button
          fontSize={'10px'}
          width={'fit-content'}
          variant={'ghost'}
          onClick={() =>
            setSearchParams((prev) => {
              prev.delete('selectedNodes');
              return prev;
            })
          }
        >
          Clear all
        </Button>
      </Flex>
      <Flex mt={'10px'} flexWrap="wrap" rowGap="0.6em">
        {sortedTags.map((item) => {
          return (
            <Box key={item.value} justifyContent={'center'} display={'flex'}>
              <TagComponent
                listName={listName}
                label={item.label}
                value={item.value}
                delFunc={deleteTagFunc}
                status={item.status}
                disabledMessage={item.disabledMessage}
              />
            </Box>
          );
        })}
      </Flex>
    </Card>
  );
};

export default DisplayTagComponent;
