import { Box, Flex, Grid, Heading, HStack } from '@chakra-ui/layout';
import { useRadioGroup } from '@chakra-ui/radio';
import { Button, Card, Input, useToast } from '@chakra-ui/react';
import Select from 'react-select';

import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppDispatch';
import { usePlmnQueryClient } from '../../services/contextClient';
import { useNodesByTechnologyClient } from '../../services/nodesClient';
import {
  useGetThresholdLists,
  useUpdateUserNodeCollectionList,
} from '../../services/userProfileClient';
import { NodeCollectionEditForm } from '../../store/modalTabSlice';
import { TechnologyType } from '../../utils/denseware-client-js/client/types';
import RadioCard from '../Radio/RadioCard';
import TagComponent from '../Select/TagComponent';
import {
  CreateNodeValues,
  SelectedNodes,
  SelectedNodeType,
} from './NodeCollectionForm';

const EditNodeCollectionForm: FC = () => {
  const id = useAppSelector(
    (state) => state.modalTabSettings.nodeCollectionEditForm.nodeCollectionId
  );
  const dispatch = useDispatch();

  const toast = useToast();
  const { register, handleSubmit } = useForm();
  const [listName, setListName] = useState<string>('');
  const [selectedNodes, setSelectedNodes] = useState<SelectedNodes>([]);
  const [isValidList, setIsValidList] = useState<boolean>(true);
  const [technologyType, setTechnologyType] = useState<TechnologyType>('4G');
  const options = ['4G', '5G'];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: '4G',
    onChange: (e: TechnologyType) => setTechnologyType(e),
  });

  const { data: NodeCollectionData, refetch: refetchList } =
    useGetThresholdLists();

  const updateNodeCollection = useUpdateUserNodeCollectionList();

  const selectedNodeCollection = NodeCollectionData?.find((profile) => {
    return profile.userProfileId === id;
  });
  const profilePayload = selectedNodeCollection?.payload;
  const listValues: CreateNodeValues = useMemo(
    () => {
      if (profilePayload) {
        return JSON.parse(profilePayload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedNodeCollection?.userProfileId]
  );

  useEffect(() => {
    if (listValues) {
      setListName(listValues.listName);
      setSelectedNodes(listValues.values);
    }
  }, [listValues]);

  useEffect(() => {
    if (
      listName !== '' &&
      selectedNodes.length > 0 &&
      listName.trim().length !== 0
    ) {
      setIsValidList(false);
    } else {
      setIsValidList(true);
    }
  }, [listName, selectedNodes]);

  const onSubmit = () => {
    return;
  };

  const group = getRootProps();

  const plmnQuery = usePlmnQueryClient();

  const { data: nodesData, isSuccess: nodesIsSuccess } =
    useNodesByTechnologyClient({
      plmns: plmnQuery.data?.allPlmns,
      technologyType: technologyType,
    });

  const onNodeSelected = (data: SelectedNodeType) => {
    setSelectedNodes((prevSelectedNodes) => {
      const newNodeState = [...prevSelectedNodes];
      newNodeState.push(data);
      return newNodeState;
    });
  };

  const onDelete = (listName: string, itemId: string) => {
    setSelectedNodes((prevSelectedNodes) => {
      return prevSelectedNodes.filter((item) => {
        return item.id !== itemId;
      });
    });
  };

  const selectOptions = nodesIsSuccess
    ? Object.entries(nodesData).map(([nodeId, item]) => {
        const entry = {
          label: item.nodeName,
          value: nodeId,
          id: nodeId,
        };
        return entry;
      })
    : [];

  const selectedOptions = selectedNodes.map((item) => {
    return item.label;
  });

  const filteredOptions: { label: string; value: string; id: string }[] =
    selectOptions
      .filter((option) => {
        return !selectedOptions.includes(option.label);
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

  const onNodeListSave = () => {
    const otherCollections = NodeCollectionData?.filter((item) => {
      if (item.sectionName == 'NodeCollection') {
        return item.userProfileId !== id;
      }
    });

    const listNameExists = otherCollections?.find((item) => {
      const payload = JSON.parse(item.payload);
      const newListName: string = payload.listName;
      if (newListName == listName) {
        return listName;
      }
      return null;
    });

    const newNodeCollectionData: CreateNodeValues = {
      listName: listName,
      values: selectedNodes,
    };
    if (id && listNameExists === undefined) {
      updateNodeCollection.mutate(
        { id, data: newNodeCollectionData },
        {
          onSuccess: () => {
            refetchList();
            toast({
              title: 'Your list is updated',
              description: '',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            dispatch(
              NodeCollectionEditForm({
                isNodeCollectionEditFormOpen: false,
                nodeCollectionId: null,
              })
            );
          },
        }
      );
    } else if (listNameExists) {
      toast({
        title: 'List Name already exists',
        description: 'Please change your List Name',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return null;
    }
  };

  return (
    <>
      <Grid gridTemplateColumns={'1fr 1fr'} gridGap={10} minH={'450px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={'20px'}>
            <Input
              id={'node-collection-list-name-input-field'}
              placeholder="Node Collection Name"
              {...register('name')}
              onChange={(e) => {
                const inputValue = e.target.value;
                setListName(inputValue.trim());
              }}
              defaultValue={listName}
            />
          </Box>
          <HStack {...group} mb={'10px'}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard key={value} {...radio}>
                  {value}
                </RadioCard>
              );
            })}
          </HStack>

          <Box mb={'20px'}>
            <Select
              id={'node-collection-select-dropdown'}
              options={filteredOptions}
              onChange={(selectedValue) => {
                if (selectedValue) {
                  onNodeSelected(selectedValue);
                }
              }}
              value={selectedNodes ? null : null}
            />
          </Box>
        </form>

        {listName !== '' || selectedNodes.length > 0 ? (
          <Box>
            <Card p={2} maxH={'250px'} overflow={'hidden'} overflowY={'scroll'}>
              <Heading size={'sm'} mb={'20px'} textTransform={'uppercase'}>
                {listName}
              </Heading>
              {[...selectedNodes]
                .sort((a, b) => {
                  return a.label.localeCompare(b.label);
                })
                .map((item) => {
                  return (
                    <Box key={`${item.id}`} mb={'5px'}>
                      <TagComponent
                        listName="Nodes"
                        label={`${item.label}`}
                        value={`${item.value}`}
                        delFunc={onDelete}
                      />
                    </Box>
                  );
                })}
            </Card>
          </Box>
        ) : null}
      </Grid>
      <Flex justifyContent={'end'} gap={'20px'}>
        <Button
          id={'cancel-button'}
          variant={'unstyled'}
          onClick={() =>
            dispatch(
              NodeCollectionEditForm({
                isNodeCollectionEditFormOpen: false,
                nodeCollectionId: null,
              })
            )
          }
        >
          Cancel
        </Button>
        <Button
          id={'update-node-collection-button'}
          isDisabled={isValidList}
          onClick={onNodeListSave}
        >
          Update
        </Button>
      </Flex>
    </>
  );
};

export default EditNodeCollectionForm;
