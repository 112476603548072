import chroma from 'chroma-js';

const baseColors = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
];
const darker = baseColors.map((c) => chroma(c).darken(1.5).hex());
const lighter = baseColors.map((c) => chroma(c).brighten(1).hex());

export const categoryColors = [...baseColors, ...darker, ...lighter];
