import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../App';
import {
  CreateNode,
  CreateNodeValues,
} from '../components/PersonalizationModalOptions/NodeCollectionForm';
import type {
  CreateThreshold,
  InProgressKpi,
  KPIValue,
  ThresholdListValues,
} from '../components/PersonalizationModalOptions/ThresholdsForm';
import dwAxiosClient from './densewareAxiosClient';

export type UserProfileId = number;

export type GetThresholdResponse = {
  userProfileId: UserProfileId;
  userId: string;
  sectionName: string;
  payload: string;
}[];

export const checkListOfKpisIsValid = (
  data: (KPIValue | InProgressKpi)[]
): data is KPIValue[] => {
  return data.every((kpi) => {
    return kpi.value !== null && kpi.color !== null;
  });
};

const createUserThresholdList = async (data: CreateThreshold) =>
  await dwAxiosClient.post(
    `/UserProfile/?sectionName=${data.sectionName}`,
    JSON.stringify(JSON.stringify(data.listData)),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

const useCreateThresholdList = () =>
  useMutation({
    mutationFn: createUserThresholdList,
    onSuccess: (result) => {
      queryClient.setQueryData<GetThresholdResponse>(
        ['existingThresholdList'],
        (oldData) => {
          if (oldData) {
            return oldData.concat([result.data]);
          }
          return [result.data];
        }
      );
    },
  });

const createUserNodeList = async (data: CreateNode) =>
  await dwAxiosClient.post(
    `/UserProfile/?sectionName=${data.sectionName}`,
    JSON.stringify(JSON.stringify(data.listData)),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

const useCreateNodesList = () =>
  useMutation({
    mutationFn: createUserNodeList,
    onSuccess: (result) => {
      queryClient.setQueryData<GetThresholdResponse>(
        ['existingThresholdList'],
        (oldData) => {
          if (oldData) {
            return oldData.concat([result.data]);
          }
          return [result.data];
        }
      );
    },
  });

const updateUserThresholdList = async (
  id: UserProfileId,
  data: ThresholdListValues
) => {
  await dwAxiosClient.put(
    `/v2.0/UserProfile`,
    JSON.stringify(JSON.stringify(data)),
    {
      params: { userProfileId: id },
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

const useUpdateUserThresholdList = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: UserProfileId;
      data: ThresholdListValues;
    }) => {
      return updateUserThresholdList(id, data);
    },
  });
};

const updateUserNodeCollectionList = async (
  id: UserProfileId,
  data: CreateNodeValues
) => {
  await dwAxiosClient.put(
    `/v2.0/UserProfile`,
    JSON.stringify(JSON.stringify(data)),
    {
      params: { userProfileId: id },
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

const useUpdateUserNodeCollectionList = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: UserProfileId;
      data: CreateNodeValues;
    }) => {
      return updateUserNodeCollectionList(id, data);
    },
  });
};

const getThresholdLists = async () => {
  const { data, status } = await dwAxiosClient.get(`/UserProfile`);
  if (status === 200) {
    return data;
  }
};

const useGetThresholdLists = () => {
  return useQuery<GetThresholdResponse>(
    ['existingThresholdList'],
    getThresholdLists,
    {
      staleTime: 10 * 60 * 60 * 1000,
      retry: false,
    }
  );
};

const deleteThresholdList = async (id: UserProfileId) => {
  const { data, status } = await dwAxiosClient.delete(
    `UserProfile?userProfileId=${id}`
  );
  if (status === 200) {
    return data;
  }
};

const useDeleteThreshold = () => {
  return useMutation({
    mutationFn: (id: UserProfileId) => {
      return deleteThresholdList(id);
    },
    onSuccess: (result, variables) => {
      queryClient.setQueryData<GetThresholdResponse>(
        ['existingThresholdList'],
        (oldData) => {
          if (oldData) {
            const newData = oldData.filter((item) => {
              return item.userProfileId !== variables;
            });
            return newData;
          }
        }
      );
    },
  });
};

export {
  useGetThresholdLists,
  useCreateThresholdList,
  updateUserThresholdList,
  useUpdateUserThresholdList,
  useUpdateUserNodeCollectionList,
  useDeleteThreshold,
  deleteThresholdList,
  useCreateNodesList,
};
