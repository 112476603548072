import { Flex } from '@chakra-ui/react';
import {
  addDays,
  differenceInDays,
  endOfMonth,
  isBefore,
  isEqual,
  isFirstDayOfMonth,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import _find from 'lodash/find';
import _meanBy from 'lodash/meanBy';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import { useChartControlsContext } from '../../contexts/ChartControlsContext';
import { useChartClient } from '../../services/chartClient';
import { useMultipleMeasuresClient } from '../../services/measureClient';
import { useNodesClient } from '../../services/nodesClient';
import { IntervalEnum } from '../../utils/denseware-client-js/client/types';
import { ChartPoint, ListOfLines } from '../lineChart/LineChart';
import { SelectedTag } from '../Select/DisplayTagComponent';
import DashboardChart from './DashboardChart';
import GraphOperations from './GraphOperations';

type CombinedNodeChartProps = {
  chartId: string;
  plmns: string[];
  dateAndTimeStart: string;
  dateAndTimeEnd: string;
  nodeNames: SelectedTag[];
  interval: IntervalEnum;
  cellNumbers: SelectedTag[];
  autoRefresh?: boolean | undefined;
};

const CombinedNodeChart: FC<CombinedNodeChartProps> = (props) => {
  const {
    chartId,
    plmns,
    dateAndTimeStart,
    dateAndTimeEnd,
    nodeNames,
    cellNumbers,
    interval,
    autoRefresh = false,
  } = props;

  const { ref, inView } = useInView();

  const { data: chartData } = useChartClient(chartId);

  const [controlsState] = useChartControlsContext();

  const nodesResponse = useNodesClient({ plmns });

  const allNodesIds =
    nodesResponse.status === 'success'
      ? nodeNames
          .filter((n) =>
            _find(
              nodesResponse.data,
              (node) => node.nodeName.toLowerCase() === n.value.toLowerCase()
            )
          )
          .map((nodeName) => {
            const foundNode = Object.entries(nodesResponse.data).find(
              (entry) => {
                const value = entry[1];
                return value.nodeName === nodeName.value;
              }
            );

            if (foundNode) {
              return foundNode[0];
            }

            return nodeName.value;
          })
      : [];

  // This is the combineNode file and the action might need to happen here

  const [searchParams] = useSearchParams();

  const nodeNamesFromUrl = searchParams.get('selectedNodes') ?? null;

  const timeZone = searchParams.get('timezone') ?? 'Etc/UTC';
  const isTimeZoneUTC = timeZone === 'Etc/UTC';

  const nodeObj: { label: string; value: string; id: string }[] | null =
    nodeNamesFromUrl !== null ? JSON.parse(nodeNamesFromUrl) : null;

  const nodes: string[] = nodeObj?.map((node) => node.id) ?? allNodesIds;

  const measuresResponses = useMultipleMeasuresClient(
    chartData ? chartData.data.data.series.map((s) => s.measureId) : [],
    {
      plmns,
      nodeId: nodes,
      cellNumber: cellNumbers.map((n) => n.value),
      dateAndTimeStart,
      dateAndTimeEnd,
      interval,
      enabled: inView,
      autoRefresh,
    }
  );

  const measuresData = measuresResponses.isSuccess
    ? measuresResponses.data
    : [];

  const labels =
    chartData && chartData.data.data.series.length > 1
      ? chartData.data.data.series.map((s) => s.title)
      : undefined;

  // 2023-03-04T06:46:51Z
  const dataForChart = measuresData
    .map((measureData) => {
      return measureData.data.data
        .filter(
          (
            d
          ): d is {
            DateAndTimeStart: string;
            DateAndTimeEnd: string;
            value: number;
          } => d.value !== null
        )
        .map((d) => {
          return {
            x: parseISO(d.DateAndTimeStart).getTime(),
            y: d.value,
          };
        });
    })
    .map((measureData) => {
      return measureData.sort((a, b) => {
        return a.x - b.x;
      });
    });

  const numberOfDaysBetweenStartAndEnd = differenceInDays(
    new Date(dateAndTimeEnd),
    new Date(dateAndTimeStart)
  );

  const validTimeInterval = interval === '86400';

  const moreThan30Days = numberOfDaysBetweenStartAndEnd > 30;

  const rollingAverages: ChartPoint[][] =
    controlsState.showRollingAverage && validTimeInterval
      ? // Go through each of the series/lines
        measuresData.map((measureData) => {
          // Reduce from point per day to only points after 30 days
          return measureData.data.data.reduce(
            (acc: ChartPoint[], point, i, data) => {
              // Only add points after 30 days
              if (i >= 29) {
                const date = parseISO(point.DateAndTimeStart);
                // Get the last 30 days
                const previous30 = data.slice(i - 29, i + 1);
                // Mean it
                const y = _meanBy(previous30, (p) => p.value ?? 0);
                //Add it
                acc.push({ x: date.getTime(), y });
              }

              return acc;
            },
            []
          );
        })
      : [];

  const isFirstOfMonth = isFirstDayOfMonth(new Date(dateAndTimeStart));
  const endOfTheMonth = endOfMonth(new Date(dateAndTimeStart));
  const nextStartOfMonth = startOfDay(addDays(endOfTheMonth, 1));

  const cAvg = controlsState.showCalendarAvg
    ? measuresData.map((measureData) => {
        return measureData.data.data.reduce(
          (acc: ChartPoint[], point, i, data) => {
            const dateToCalculate = parseISO(point.DateAndTimeStart);

            // Skip if they chose a start date part way through
            // and we're calculating for a date before the start of the next month start
            if (
              !isFirstOfMonth &&
              isBefore(dateToCalculate, nextStartOfMonth)
            ) {
              return acc;
            }

            // Beginning of the month for the point we're calculating
            const firstOfMonth = startOfMonth(dateToCalculate);

            // Get all the points from the start of the month up to the date we're calculating
            const pointsWithinTheMonth = data.filter((p) => {
              const pDate = parseISO(p.DateAndTimeStart);
              return isWithinInterval(pDate, {
                start: firstOfMonth,
                end: dateToCalculate,
              });
            });

            const theMean = _meanBy(pointsWithinTheMonth, (p) => p.value ?? 0);
            acc.push({
              x: dateToCalculate.getTime(),
              y: theMean,
            });

            return acc;
          },
          []
        );
      })
    : [];

  const allData: ListOfLines = dataForChart.map((chartD, i) => {
    return {
      data: chartD,
      additionalData: [
        ...(rollingAverages.length > 0
          ? [{ data: rollingAverages[i], color: 'red', label: '30d avg' }]
          : []),
        ...(cAvg.length > 0
          ? [{ data: cAvg[i], color: 'yellow', label: 'Calendar avg' }]
          : []),
      ],
    };
  });

  const arrayOfMetricsData = measuresData.map((resp) => resp.data.data);

  const exportDataRecords: Record<string, string | number | null>[][] =
    arrayOfMetricsData.map((dataset, i) => {
      return dataset.map((p) => {
        const date = parseISO(p.DateAndTimeStart);
        const value = p.value;
        const rolling =
          rollingAverages.length > 0
            ? rollingAverages[i].find((av) => isEqual(av.x, date))
            : undefined;
        const calAvg =
          cAvg.length > 0
            ? cAvg[i].find((av) => isEqual(av.x, date))
            : undefined;

        const row: Record<string, string | number | null> = {
          dateAndTimeStart: p.DateAndTimeStart,
          dateAndTimeEnd: p.DateAndTimeEnd,

          ...(!isTimeZoneUTC && {
            [`dateAndTimeStart (${
              DateTime.fromISO(p.DateAndTimeStart).setZone(timeZone)
                .offsetNameLong
            })`]: DateTime.fromISO(p.DateAndTimeStart)
              .setZone(timeZone)
              .toISO({ suppressMilliseconds: true }),
            [`dateAndTimeEnd (${
              DateTime.fromISO(p.DateAndTimeStart).setZone(timeZone)
                .offsetNameLong
            })`]: DateTime.fromISO(p.DateAndTimeEnd)
              .setZone(timeZone)
              .toISO({ suppressMilliseconds: true }),
          }),

          value: value,

          ...(rolling && { thirtyDayRollingAvg: rolling.y }),
          ...(calAvg && { calendarMonthAvg: calAvg.y }),
        };

        return row;
      });
    });

  const exportData = exportDataRecords.flatMap((element) => {
    return element;
  });

  const ExportNodeNames = nodeNames.map((nodes) => {
    return nodes.label;
  });

  return (
    <Flex ref={ref} height="100%" flexDirection="column">
      <DashboardChart
        chartId={chartId}
        measureDataStatus={measuresResponses.status}
        description={chartData?.data.data.description}
        data={allData}
        labels={labels}
        title={chartData?.data.data.title}
        refetch={measuresResponses.refetch}
        y0Label={chartData?.data.data.axisLabels.y0Label}
      />
      {measuresResponses.isSuccess ? (
        <GraphOperations
          lastFetched={measuresResponses.dataUpdatedAt}
          refetch={measuresResponses.refetch}
          exportName={'Export'}
          title={chartData?.data.data.title}
          chartData={exportData}
          extraRowName={'Selected Nodes'}
          extraRowData={ExportNodeNames}
          hasGlobalSLAs={chartData?.data.data.series.some(
            (s) => s.thresholds.length > 0
          )}
          enableRollingAverage={validTimeInterval && moreThan30Days}
          enableCalendarAverage={validTimeInterval}
        />
      ) : null}
    </Flex>
  );
};

export default CombinedNodeChart;
