import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';

import { FC, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import {
  useDeleteThreshold,
  UserProfileId,
} from '../../services/userProfileClient';

type DeleteItemProps = {
  id: number;
  sectionName: string;
  itemName: string;
};

const DeleteItem: FC<DeleteItemProps> = ({ id, sectionName, itemName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const deleteThresholdList = useDeleteThreshold();

  const onKPIDeleteClicked = (id: UserProfileId) => {
    deleteThresholdList.mutate(id);
  };
  return (
    <>
      <Button
        onClick={onOpen}
        id={`delete-item-button-${sectionName}-${itemName}`}
        borderLeft={'1px solid white'}
        borderLeftRadius={'none'}
        p={0}
      >
        <Icon as={AiOutlineClose} color={'white'} />
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Item
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete{' '}
              <span style={{ fontWeight: 'bold' }}>{itemName} ? </span>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                background={'errorRed'}
                _hover={{
                  background: 'errorRed',
                }}
                onClick={() => onKPIDeleteClicked(id)}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteItem;
