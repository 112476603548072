import { Switch } from '@chakra-ui/react';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

const AutoRefreshSwitch: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isAutoRefresh =
    searchParams.get('autoRefresh') === 'true' ? true : false;

  return (
    <Switch
      isChecked={isAutoRefresh}
      onChange={() => {
        setSearchParams((s) => {
          const newValue = isAutoRefresh ? 'false' : 'true';
          s.set('autoRefresh', newValue);
          return s;
        });
      }}
    >
      Auto Refresh
    </Switch>
  );
};

export default AutoRefreshSwitch;
