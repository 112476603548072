import { Box, Flex, Grid } from '@chakra-ui/react';
import { set, sub } from 'date-fns';
import { Fragment, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ThresholdsSelection from '../components/ChartOperations/ThresholdsSelection';
import ChartsSection from '../components/Charts/ChartsSection';
import DashboardTitle from '../components/DashboardTitle/DashboardTitle';
import Footer from '../components/Footer/Footer';
import GraphFilterChoice from '../components/GraphFilter/GraphFilterChoice';
import Header from '../components/Header/Header';
import { SelectedTag } from '../components/Select/DisplayTagComponent';
import Title from '../components/Title/Title';
import {
  ChartFiltersContext,
  NodeToggleOption,
} from '../contexts/ChartFiltersContext';
import { usePlmnByProviderQueryClient } from '../services/contextClient';
import { useNodesClient } from '../services/nodesClient';
import { IntervalEnum } from '../utils/denseware-client-js/client/types';
import {
  saveSearchParamsInStorage,
  setSearchParamsFromStorage,
} from '../utils/GetSearchParams';

const prevMonth = set(sub(new Date(), { days: 30 }), {
  hours: 0,
  minutes: 0,
  seconds: 0,
});
const today = set(new Date(), {
  hours: 23,
  minutes: 59,
  seconds: 59,
});

const DashboardPage = () => {
  saveSearchParamsInStorage();
  useEffect(() => {
    setSearchParamsFromStorage();
  }, []);
  const params = useParams();
  const { dashboardId } = params;

  const [searchParams] = useSearchParams();

  const plmnsQuery = usePlmnByProviderQueryClient({
    countryCode: searchParams.get('countryCode'),
    providerName: searchParams.get('providerName'),
  });

  const nodesQuery = useNodesClient({
    plmns: plmnsQuery.data?.plmns,
  });

  const autoRefresh = searchParams.get('autoRefresh') === 'true' ? true : false;

  if (nodesQuery.status !== 'success') {
    return null;
  }

  const nodeNamesFromUrl = searchParams.get('selectedNodes') ?? null;

  const nodeNamesFromApi = Object.entries(nodesQuery.data).map(
    ([nodeId, nodeValue]) => ({
      label: nodeValue.nodeName,
      value: nodeValue.nodeName,
      id: nodeId,
    })
  );

  const nodeNames = nodeNamesFromUrl
    ? (JSON.parse(nodeNamesFromUrl) as SelectedTag[])
    : nodeNamesFromApi;

  const dateAndTimeStart =
    searchParams.get('fromDate') ?? prevMonth.toISOString();
  const dateAndTimeEnd = searchParams.get('toDate') ?? today.toISOString();

  const cellNumbersFromUrl = searchParams.get('selectedCells') ?? '';
  const cellNumbers = cellNumbersFromUrl
    ? (JSON.parse(cellNumbersFromUrl) as SelectedTag[])
    : ([] as SelectedTag[]);

  const interval =
    (searchParams.get('timeInterval') as IntervalEnum) ?? '86400';

  const nodeToggle =
    (searchParams.get('nodeToggle') as NodeToggleOption) ?? 'combine-nodes';

  return (
    <Grid>
      <Header />
      <Box padding={'0 20px'}>
        <Title />
        <Grid
          minHeight="100vh"
          templateRows="max-content max-content max-content"
        >
          {dashboardId && plmnsQuery.isSuccess ? (
            <Fragment>
              <DashboardTitle
                dashboardId={dashboardId}
                key={`${dashboardId}` + 'title'}
              />
              <GraphFilterChoice dashboardId={dashboardId} />
              <Flex justifyContent={'end'}>
                <ThresholdsSelection />
              </Flex>

              <ChartFiltersContext.Provider
                value={{
                  plmns: plmnsQuery.data.plmns,
                  cellNumbers,
                  interval,
                  nodeNames,
                  dateAndTimeEnd,
                  dateAndTimeStart,
                  nodeToggle,
                  autoRefresh,
                }}
              >
                <ChartsSection dashboardId={dashboardId} key={dashboardId} />
              </ChartFiltersContext.Provider>
            </Fragment>
          ) : null}
        </Grid>
      </Box>
      <Footer
        aboutUsHref={import.meta.env.VITE_APP_ABOUT_US}
        version={import.meta.env.VITE_APP_VERSION}
      />
    </Grid>
  );
};

export default DashboardPage;
