import { Icon } from '@chakra-ui/react';
import { decodeJwt } from 'jose';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';

export type jwtToken = {
  FirstName: string;
  LastName: string;
  exp: number;
};

export type TokenPayload = {
  FirstName: string;
  LastName: string;
  Organization: string;
  PhoneNumber: string;
  ProviderId: string[];
  ViewDataValues: string;
  ViewRegionTag: string[];
  amr: string[];
  auth_time: number;
  client_id: string;
  email: string;
  exp: number;
  iat: number;
  idp: string;
  iss: string;
  jti: string;
  nbf: number;
  role: string | string[];
  scope: string[];
  sub: string;
};

export const getToken = () => {
  const token = localStorage.getItem('dw_authToken');
  if (token) {
    return token;
  }
};

export const useGetToken = () => {
  const token = getToken();
  if (token) {
    const jwt = decodeJwt(token);
    const todayDate = new Date();
    if (jwt.exp) {
      if (jwt.exp * 1000 > todayDate.getTime()) {
        return jwt;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  return null;
};

export const getInitials = () => {
  const token = localStorage.getItem('dw_authToken');
  if (token) {
    const jwt = decodeJwt(token) as jwtToken;
    const firstInitial = jwt.FirstName.charAt(0).toLocaleUpperCase();
    const lastInitial = jwt.LastName.charAt(0).toLocaleUpperCase();
    return `${firstInitial}${lastInitial}`;
  }
  return <Icon as={AiOutlineUser} />;
};

export const AuthProvider = () => {
  const token = useGetToken();
  const URL = import.meta.env.VITE_APP_LOGIN_PATH;

  useEffect(() => {
    if (!token) {
      localStorage.removeItem('dw_authToken');
      const location = window.location.href;
      const encodedLocation = encodeURIComponent(location);
      const navigationUrl = `${URL}?redirectUrl=${encodedLocation}`;
      window.location.replace(navigationUrl);
    }
  }, [token, URL]);

  if (token) {
    return <Outlet />;
  }

  return null;
};

export const getAuthProperty = <K extends keyof TokenPayload>(
  propertyName: K
) => {
  const tokenResult = getToken();

  if (tokenResult) {
    const token = tokenResult;
    const decodedToken = decodeJwt(token) as TokenPayload;
    const value = decodedToken[propertyName];

    if (value) {
      return value;
    }
    return `Property ${propertyName} not found`;
  }

  return tokenResult;
};

export const checkApplicationAccess = (role: string): boolean => {
  const tokenResult = getToken();

  if (tokenResult) {
    const roles = getAuthProperty('role');
    const rolesArray = roles;
    if (rolesArray?.includes(role)) {
      return true;
    }
  }
  return false;
};

export const isInternalUser = () => {
  const tokenResult = getToken();

  if (tokenResult) {
    const decodedToken = decodeJwt(tokenResult) as TokenPayload;

    const role = decodedToken.role;

    if (Array.isArray(role)) {
      return role.some((r) => r.includes('DenseAir'));
    } else {
      return role.includes('DenseAir');
    }
  } else {
    return false;
  }
};
