import { Button } from '@chakra-ui/react';
import {
  bbox,
  bboxPolygon,
  Feature,
  featureCollection,
  transformScale,
} from '@turf/turf';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SelectedNodesData } from './ActionsModal';

type nodeButtonOptions = {
  points?: Feature[];
  countryCode: string;
  providerName: string;
  isDisabled: boolean;
  selectedNodes?: SelectedNodesData[];
  name: string;
  fromDate: string;
};

const AllNodesButton: FC<nodeButtonOptions> = ({
  points,
  countryCode,
  providerName,
  isDisabled,
  selectedNodes,
  fromDate,
  name,
}) => {
  if (points && points.length > 0) {
    const collection = featureCollection(points);
    const bboxOfCollection = bbox(collection);
    const polyOfCollection = bboxPolygon(bboxOfCollection);

    const largerBboxPoly = transformScale(polyOfCollection, 1.5);
    const boundingBoxValues = bbox(largerBboxPoly.geometry);

    const country = encodeURIComponent(countryCode);
    const provider = encodeURIComponent(providerName);

    const link = new URL(
      `${
        import.meta.env.VITE_APP_GEO
      }/#countryCode=${country}&providerName=${provider}&bbox=${boundingBoxValues}&layers=["oss"]`
    );

    return (
      <Link to={`${link}`}>
        <Button isDisabled={isDisabled}>
          View {points.length} nodes in {name}
        </Button>
      </Link>
    );
  }

  if (selectedNodes) {
    const encodedDate = JSON.parse(fromDate);
    const nodeNames = selectedNodes.map((n) => {
      return JSON.stringify(n.nodeName);
    });
    const link = `${
      import.meta.env.VITE_APP_MONITOR
    }events?from=${encodedDate}&nodes=[${nodeNames}]`;

    return (
      <Link to={`${link}`}>
        <Button isDisabled={isDisabled}>
          View {nodeNames.length} nodes in {name}
        </Button>
      </Link>
    );
  }
  return null;
};

export default AllNodesButton;
