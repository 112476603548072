import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Qs from 'qs';
import type {
  GetAlarmsOptions,
  GetAlarmsResponse,
  GetDashboardsRequestOptions,
  GetMeasureRequestOptions,
  GetMeasureResponse,
  GetMeasuresForMeasureIdByNode,
  GetMeasuresForMeasureIdByNodeResponse,
  GetMeasuresForMeasureIdForNodeByCellOptions,
  GetMeasuresForMeasureIdForNodeByCellResponse,
  GetMeasuresForMeasureIdForNodeOptions,
  GetMeasuresForMeasureIdForNodeResponse,
  GetMeasuresListResponse,
  GetMeasuresRequestOptions,
  GetMeasuresResponse,
  GetNodesRequestOptions,
  GetNodesResponse,
  MetadataChartResponse,
  MetadataDashboardResponse,
  MetadataDashboardsResponse,
} from './types';

type InitializeOptions = {
  baseUrl: string;
  version: string;
  getToken: () => string;
};

class Client {
  private version = 'v1';

  private _axios: AxiosInstance | null = null;
  private get axios(): AxiosInstance {
    if (this._axios === null) {
      throw new Error('Client Axios not initialized');
    }
    return this._axios;
  }
  private set axios(value: AxiosInstance | null) {
    this._axios = value;
  }

  constructor(options: InitializeOptions) {
    const { baseUrl, version } = options;

    this.version = version;

    try {
      const axiosInstance = axios.create({
        baseURL: baseUrl,
        paramsSerializer: {
          serialize: (params: Record<string, unknown>) => {
            return Qs.stringify(params, {
              arrayFormat: 'repeat',
              filter(prefix, value) {
                if (['plmn'].includes(prefix)) {
                  return value.join(',');
                }

                return value;
              },
            });
          },
        },
      });

      axiosInstance.interceptors.request.use((config) => {
        const token = options.getToken();
        config.headers.Authorization = `Bearer ${token}`;

        return config;
      });

      this._axios = axiosInstance;
    } catch (e) {
      // TODO: Use the Logger
      // eslint-disable-next-line no-console
      console.error("Couldn't create client instance", e);
    }

    return this;
  }

  public updateApiKey(apiKey: string) {
    this.axios.defaults.params.apiKey = apiKey;
  }

  private get<T>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      return this.axios.get<T>(path, config);
    } catch (e) {
      return Promise.reject();
    }
  }

  metadata = {
    getDashboards: async (options: GetDashboardsRequestOptions) => {
      return this.get<MetadataDashboardsResponse>(
        `/denseware/metadata/${this.version}/dashboards`,
        { params: options.params }
      );
    },
    getDashboard: async (path: { dashboardId: string }) => {
      return this.get<MetadataDashboardResponse>(
        `/denseware/metadata/${this.version}/dashboards/${path.dashboardId}`
      );
    },
    getChart: async (path: { chartId: string }) => {
      return this.get<MetadataChartResponse>(
        `/denseware/metadata/${this.version}/charts/${path.chartId}`
      );
    },
  };

  measures = {
    getMeasuresList: async () => {
      return this.get<GetMeasuresListResponse>(
        `/denseware/data/${this.version}/measures/list`
      );
    },
    getMeasures: async (options: GetMeasuresRequestOptions) => {
      return this.get<GetMeasuresResponse>(
        `denseware/data/${this.version}/measures`,
        { params: options.params }
      );
    },
    getMeasuresForMeasureId: async (options: GetMeasureRequestOptions) => {
      return this.get<GetMeasureResponse>(
        `/denseware/data/${this.version}/measures/${options.path.measureId}`,
        { params: options.params }
      );
    },
  };

  nodes = {
    getNodes: async (options: GetNodesRequestOptions) => {
      return this.get<GetNodesResponse>(
        `/denseware/data/${this.version}/nodes`,
        {
          params: options.params,
        }
      );
    },
    getMeasuresForMeasureIdByNode: async (
      options: GetMeasuresForMeasureIdByNode
    ) => {
      return this.get<GetMeasuresForMeasureIdByNodeResponse>(
        `/denseware/data/${this.version}/nodes/measures/${options.path.measureId}`,
        { params: options.params }
      );
    },
    getMeasuresForMeasureIdForNode: async (
      options: GetMeasuresForMeasureIdForNodeOptions
    ) => {
      return this.get<GetMeasuresForMeasureIdForNodeResponse>(
        `denseware/data/${this.version}/nodes/${options.path.nodeId}/measures/${options.path.measureId}`,
        { params: options.params }
      );
    },
    getMeasuresForMeasureIdForNodeByCell: async (
      options: GetMeasuresForMeasureIdForNodeByCellOptions
    ) => {
      return this.get<GetMeasuresForMeasureIdForNodeByCellResponse>(
        `denseware/data/${this.version}/nodes/${options.path.nodeId}/cells/measures/${options.path.measureId}`,
        { params: options.params }
      );
    },
  };

  alarms = {
    getAlarms: async (options: GetAlarmsOptions) => {
      return this.get<GetAlarmsResponse>(
        `denseware/data/${this.version}/alarms`,
        { params: options.params }
      );
    },
    // getAlarmsForAlarmId: async (options: GetAlarmsForAlarmIdOptions) => {
    //   return this._get<GetAlarmsForAlarmIdResponse>(
    //     `denseware/data/${this.version}/alarms/${options.path.alarmId}`,
    //     { params: options.params }
    //   );
    // },
    // getAlarmsByNode: async (options: GetAlarmsByNodeOptions) => {
    //   return this._get<GetAlarmsByNodeResponse>(
    //     `denseware/data/${this.version}/nodes/alarms`,
    //     { params: options.params }
    //   );
    // },
    // getAlarmsForNodeId: async (options: GetAlarmsForNodeIdOptions) => {
    //   return this._get<GetAlarmsForNodeIdResponse>(
    //     `denseware/data/${this.version}/nodes/${options.path.nodeId}/alarms`,
    //     { params: options.params }
    //   );
    // },
  };
}

export default Client;
