import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FC, Fragment, useState } from 'react';
import {
  AiFillEdit,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
} from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppDispatch';
import {
  checkListOfKpisIsValid,
  useUpdateUserThresholdList,
} from '../../services/userProfileClient';
import {
  PersonalizationModalState,
  ThresholdEditFormState,
} from '../../store/modalTabSlice';
import { GetMeasuresListResponse } from '../../utils/denseware-client-js/client/types';
import { KPIValue, ThresholdListValues } from './ThresholdsForm';

type InProgressKpi = {
  name: string;
  id: string;
  value: number | null;
  color: string | null;
};

type MiniModal = {
  data: {
    userProfileId: number;
    userId: string;
    sectionName: string;
    payload: string;
  };
  measuresList: GetMeasuresListResponse['data'];
  measureIds: string[];
  refetch: () => void;
};

const EditMiniModal: FC<MiniModal> = (props) => {
  const {
    measureIds,
    data: listDataFromApi,
    refetch: refetchList,
    measuresList,
  } = props;

  const toast = useToast();

  const listDataPayload = JSON.parse(listDataFromApi.payload);

  const { onClose } = useDisclosure();

  const dispatch = useDispatch();

  const selectedId = useAppSelector(
    (state) => state.personalizationSetting.selectedListId
  );

  const [showValueIndex, setShowValueIndex] = useState<number>();
  const [showValueInput, setShowValueInput] = useState<boolean>();

  const updateThresholdList = useUpdateUserThresholdList();

  const listName = listDataPayload.listName;

  const availableKPIList: ThresholdListValues = listDataPayload;

  const [inProgressAvailableKPIList, setInProgressAvailableKPIList] = useState<
    (KPIValue | InProgressKpi)[]
  >(availableKPIList.values);

  const onDeleteKPI = (index: number | null) => {
    setInProgressAvailableKPIList(
      inProgressAvailableKPIList?.filter((ele, i) => {
        return i !== index;
      })
    );
  };

  const onCancelClicked = () => {
    setInProgressAvailableKPIList(availableKPIList.values);
  };

  const changeValue = (index: number) => {
    setShowValueIndex(index);
    setShowValueInput(!showValueInput);
  };

  const handleColorChange = (newColor: string, index: number | null) => {
    const newList = inProgressAvailableKPIList.map((item, i) => {
      if (i === index) {
        item.color = newColor;
      }
      return item;
    });
    setInProgressAvailableKPIList(newList);
  };

  const handleValueChange = (newValue: number, index: number | null) => {
    const newList = inProgressAvailableKPIList.map((item, i) => {
      if (i === index) {
        item.value = newValue;
      }
      return item;
    });
    setInProgressAvailableKPIList(newList);
  };

  const isFormValid = checkListOfKpisIsValid(inProgressAvailableKPIList);

  const onThresholdUpdate = () => {
    // Check if

    if (isFormValid) {
      const data: ThresholdListValues = {
        listName: listName ? listName : '',
        values: inProgressAvailableKPIList,
      };

      updateThresholdList.mutate(
        { id: selectedId ? selectedId : 0, data },
        {
          onSuccess: () => {
            refetchList();
            toast({
              title: 'Your list is updated',
              description: '',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            onClose();
          },
        }
      );
    }
  };

  const howManyReleventThresholds = inProgressAvailableKPIList.reduce(
    (acc, kpi) => {
      if (measureIds.includes(kpi.id)) {
        acc++;
      }

      return acc;
    },
    0
  );

  return (
    <>
      {listDataPayload ? (
        <Popover>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  borderRadius={'5px'}
                  cursor={'pointer'}
                  padding={'3px 10px'}
                  height={'auto'}
                >
                  <AiFillEdit size={12} />
                  <Text ml={2} fontSize={'12px'}>
                    {listName}
                  </Text>
                </Button>
              </PopoverTrigger>
              <PopoverContent p={'10px'} width={'100%'}>
                <Box mb={2} mt={2}>
                  <Heading size={'sm'}>{listName}</Heading>
                </Box>

                <Box>
                  {inProgressAvailableKPIList
                    ?.sort((a) => {
                      const isTop = measureIds?.includes(a.id);

                      return isTop ? -1 : 1;
                    })
                    .map((threshold, index) => {
                      const isFirstAndApplicableMeasure =
                        measureIds.includes(threshold.id) && index === 0;
                      const isFirstOtherAndNotApplicable =
                        !measureIds.includes(threshold.id) &&
                        index === howManyReleventThresholds;
                      return (
                        <Fragment key={index}>
                          {isFirstAndApplicableMeasure ? (
                            <Heading
                              size={'sm'}
                              mt={'20px'}
                              mb={'10px'}
                              fontWeight={'light'}
                              fontSize={'15px'}
                            >
                              Thresholds for current graph
                            </Heading>
                          ) : isFirstOtherAndNotApplicable ? (
                            <Heading
                              size="sm"
                              mt={'20px'}
                              mb={'10px'}
                              fontWeight={'light'}
                              fontSize={'15px'}
                            >
                              Other thresholds
                            </Heading>
                          ) : null}
                          <Grid
                            gridTemplateColumns={'25px 50px 300px 1fr 1fr 1fr'}
                            background={'primary'}
                            color={'white'}
                            borderRadius={7}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mb={1.5}
                            minH={'50px'}
                          >
                            <Box
                              ml={'10px'}
                              mr={'10px'}
                              width={'20px'}
                              height={'20px'}
                              borderRadius={'5px'}
                              background={
                                threshold.color ? threshold.color : 'primary'
                              }
                            ></Box>

                            <Menu>
                              <Button
                                as={MenuButton}
                                p={'0px'}
                                m={'0px'}
                                width={'10px'}
                                ml={'10px'}
                              >
                                <ChevronDownIcon p={'0px'} m={'0px'} />
                              </Button>
                              <MenuList>
                                <MenuItem
                                  color={'black'}
                                  onClick={() => {
                                    handleColorChange('#700202', index);
                                  }}
                                >
                                  Red
                                </MenuItem>

                                <MenuItem
                                  color={'black'}
                                  onClick={() => {
                                    handleColorChange('#255325', index);
                                  }}
                                >
                                  Green
                                </MenuItem>

                                <MenuItem
                                  color={'black'}
                                  onClick={() => {
                                    handleColorChange('#dab600', index);
                                  }}
                                >
                                  Yellow
                                </MenuItem>
                              </MenuList>
                            </Menu>

                            <Text pl={3}>{threshold.name}</Text>
                            <Button
                              borderRadius={0}
                              p={'10px'}
                              onClick={() => changeValue(index)}
                            >
                              {showValueInput && showValueIndex === index ? (
                                <Icon as={AiOutlineCheck} />
                              ) : (
                                <Icon as={AiOutlineEdit} />
                              )}
                            </Button>
                            {showValueInput && showValueIndex === index ? (
                              <Input
                                type={'text'}
                                borderRadius={0}
                                background={'white'}
                                color={'black'}
                                width={'40px'}
                                pl={2}
                                pr={2}
                                onChange={(e) => {
                                  handleValueChange(
                                    JSON.parse(e.target.value),
                                    index
                                  );
                                }}
                              />
                            ) : (
                              <Text p={'0 10px'}>{threshold.value}</Text>
                            )}

                            <Button
                              pl={'10px'}
                              borderLeft={'1px solid white'}
                              borderLeftRadius={'none'}
                              p={0}
                              onClick={() => onDeleteKPI(index)}
                            >
                              <Icon as={AiOutlineClose} color={'white'} />
                            </Button>
                          </Grid>
                        </Fragment>
                      );
                    })}

                  <Flex justifyContent={'end'} gap={'20px'}>
                    {measureIds && measureIds?.length > 1 ? (
                      <Menu>
                        <MenuButton>Add </MenuButton>
                        <MenuList>
                          {measureIds?.map((measureId) => {
                            return (
                              <MenuItem
                                key={measureId}
                                onClick={() => {
                                  setInProgressAvailableKPIList([
                                    ...inProgressAvailableKPIList,
                                    {
                                      color: '',
                                      id: measuresList[measureId].title,
                                      name: measureId,
                                      value: 0,
                                    },
                                  ]);
                                }}
                              >
                                {measureId}
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </Menu>
                    ) : (
                      <Button
                        onClick={() => {
                          setInProgressAvailableKPIList([
                            ...inProgressAvailableKPIList,
                            {
                              color: null,
                              id: measureIds[0],
                              name: measuresList[measureIds[0]].title,
                              value: null,
                            },
                          ]);
                        }}
                      >
                        Add value
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        dispatch(
                          ThresholdEditFormState({
                            isThresholdEditFormOpen: true,
                            thresholdEditId: listDataFromApi.userProfileId,
                          })
                        );
                        dispatch(PersonalizationModalState(true));
                      }}
                    >
                      Add Item
                    </Button>
                    <Button
                      variant={'unstyled'}
                      onClick={() => {
                        onCancelClicked();
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={onThresholdUpdate}
                      isDisabled={!isFormValid}
                    >
                      Update
                    </Button>
                  </Flex>
                </Box>
              </PopoverContent>
            </>
          )}
        </Popover>
      ) : null}
    </>
  );
};

export default EditMiniModal;
