import { IconButton } from '@chakra-ui/react';
import { set, sub } from 'date-fns';
import { GiAnticlockwiseRotation } from 'react-icons/gi';
import { useSearchParams } from 'react-router-dom';
import { removeSearchFromStorage } from '../../utils/GetSearchParams';

const ResetFilters = () => {
  const setSearchParams = useSearchParams()[1];
  const prevMonth = set(sub(new Date(), { days: 30 }), {
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const resetFilterFunc = () => {
    removeSearchFromStorage();

    setSearchParams((prev) => {
      prev.set('timeInterval', '86400');
      prev.set('countryCode', 'US');
      prev.set('providerName', 'AT&T');
      prev.delete('selectedNodes');
      prev.delete('selectedCells');
      prev.set(
        'toDate',
        set(new Date(), { hours: 23, minutes: 59, seconds: 59 }).toISOString()
      );
      prev.set('fromDate', prevMonth.toISOString());
      prev.set('nodeToggle', 'per-node');

      return prev;
    });
  };

  return (
    <IconButton
      id={'reset-filters'}
      height={'40px'}
      width={'30px'}
      mt={'23px'}
      color={'white'}
      icon={<GiAnticlockwiseRotation />}
      aria-label={'resetFilters'}
      onClick={resetFilterFunc}
      variant="warning"
    />
  );
};

export default ResetFilters;
