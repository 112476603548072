import { Box, Link } from '@chakra-ui/react';
import { FC } from 'react';

type FooterProps = {
  aboutUsHref: string;
  version: string;
};

const Footer: FC<FooterProps> = (props) => {
  const { aboutUsHref, version } = props;

  return (
    <Box
      background={'secondary'}
      paddingY="0"
      paddingX="3"
      display={'grid'}
      gridTemplateColumns={'1fr 1fr'}
      alignItems={'center'}
    >
      <Box display={'flex'}>
        <Link
          href={aboutUsHref}
          isExternal
          p={'10px'}
          aria-label="Visit About Us page"
        >
          <Box>About Us</Box>
        </Link>
      </Box>
      <Box
        id="footer-copy"
        display={'flex'}
        justifyContent={'end'}
        color={'gray'}
      >
        © 2023 Product of Dense Air {version}
      </Box>
    </Box>
  );
};

export default Footer;
