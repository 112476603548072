import { useChartClient } from '../services/chartClient';
import {
  useGetThresholdLists,
  UserProfileId,
} from '../services/userProfileClient';
import { useAppSelector } from './useAppDispatch';

export const useFilterThresholds = (chartId: string) => {
  const { data: thresholdListData, status } = useGetThresholdLists();

  const { data: chartData, status: chartDataStatus } = useChartClient(chartId);

  const selectedListId = useAppSelector(
    (state) => state.personalizationSetting.selectedListId
  );

  const existingListData: {
    id: UserProfileId;
    listName: string;
    values: { name: string; value: number; id: string; color: string | null }[];
  }[] = [];

  let thresholdValues: { value: number; color: string | null }[] | undefined =
    undefined;

  if (status === 'success' && chartDataStatus === 'success') {
    thresholdListData.map((item) => {
      const thresholdDataPayload = JSON.parse(item.payload);
      const name = thresholdDataPayload.listName;
      const values = thresholdDataPayload.values;

      const allData = {
        id: item.userProfileId,
        listName: name,
        values: values,
      };

      existingListData.push(allData);
    });
    const selectedListData = existingListData.find((item) => {
      return item.id === selectedListId;
    });

    if (selectedListData) {
      const measuresIDs = chartData.data.data.series.map((item) => {
        return item.measureId;
      });

      const matchingKPIs = measuresIDs.filter((value) =>
        selectedListData.values.find((listValue) => listValue.id === value)
      );

      const thresholdData = matchingKPIs.flatMap((item) => {
        return selectedListData.values.filter((listVal) => {
          if (listVal.id === item) {
            return { value: listVal.value, color: listVal.color };
          }
        });
      });

      thresholdValues = thresholdData.map((item) => {
        return { value: item.value, color: item.color };
      });
    }
  }
  return thresholdValues;
};
