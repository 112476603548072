import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

type CalendarComponentProps = {
  name: string;
  selectedDate: Date;
  dateChangedFn: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
  filterTime?: (date: Date) => boolean;
  helpComponent?: ReactNode;
};

const CalendarComponent: FC<CalendarComponentProps> = ({
  name,
  selectedDate,
  dateChangedFn,
  maxDate,
  minDate,
  filterTime,
  helpComponent,
}) => {
  return (
    <Box>
      <Flex>
        <Text mr={'5px'}>{name}</Text>
        {helpComponent ? helpComponent : null}
      </Flex>
      <Box border={'1px solid black'} borderRadius={'3px'}>
        <DatePicker
          dateFormat={'dd MMM yyyy HH:mm'}
          timeFormat={'HH:mm'}
          selected={selectedDate}
          showTimeSelect
          onChange={(date: Date) => dateChangedFn(date)}
          maxDate={maxDate}
          minDate={minDate}
          filterTime={filterTime}
          timeIntervals={5}
          id={`datepicker-input-${name}`}
          portalId={`datepicker-wrapper-${name}`}
        />
      </Box>
    </Box>
  );
};

export default CalendarComponent;
