import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { useGetThresholdLists } from '../../services/userProfileClient';
import {
  PersonalizationModalState,
  ThresholdTab,
} from '../../store/modalTabSlice';

export type NodeListSelectionType = {
  showAddItemButton: boolean;
};

const NodeListSelection: FC<NodeListSelectionType> = ({
  showAddItemButton,
}) => {
  const { data, isSuccess } = useGetThresholdLists();
  const dispatch = useDispatch();
  const rawNodeLists = isSuccess
    ? data.filter((d) => d.sectionName === 'NodeCollection')
    : [];

  const parsedNodeLists = rawNodeLists.map((n) => ({
    ...n,
    payload: JSON.parse(n.payload),
  }));

  const setSearchParams = useSearchParams()[1];

  return (
    <Popover>
      <PopoverTrigger>
        <Flex
          alignContent={'center'}
          alignItems={'center'}
          borderRadius={5}
          border={'1px solid black'}
        >
          <Button
            variant={'unstyledLink'}
            _hover={{
              background: 'transparent',
            }}
          >
            Collections
          </Button>
          <Box
            display={'flex'}
            alignItems={'center'}
            borderLeft={'1px solid gray'}
            p={'1px'}
            height={'20px'}
          >
            <ChevronDownIcon fontSize={'26px'} color={'gray'} m={'0 5px'} />
          </Box>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody p={'20px'}>
            <Select
              id="node-collections-list"
              isClearable
              styles={{
                control: (baseStyles) => {
                  return { ...baseStyles, borderColor: 'black' };
                },
                placeholder: (baseStyles) => {
                  return { ...baseStyles, color: 'black' };
                },
                indicatorSeparator: (baseStyles) => {
                  return { ...baseStyles, backgroundColor: 'black' };
                },
              }}
              onChange={(e) => {
                if (e) {
                  const listItem = parsedNodeLists.find(
                    (l) => l.userProfileId === e.value
                  );

                  if (listItem) {
                    setSearchParams((p) => {
                      p.set(
                        'selectedNodes',
                        JSON.stringify(listItem.payload.values)
                      );
                      return p;
                    });
                  }
                } else if (e === null || e === undefined) {
                  setSearchParams((p) => {
                    p.delete('selectedNodes');
                    return p;
                  });
                }
              }}
              options={parsedNodeLists.map((n) => {
                return { value: n.userProfileId, label: n.payload.listName };
              })}
            />
            {showAddItemButton ? (
              <Button
                mt={'10px'}
                onClick={() => {
                  dispatch(PersonalizationModalState(true));
                  dispatch(ThresholdTab('NodeCollection'));
                }}
              >
                Add Item
              </Button>
            ) : null}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default NodeListSelection;
