import { useState } from 'react';

export const useUniqueListState = <T extends string | number>(
  initialState: T[]
) => {
  const [state, setState] = useState(initialState);

  const setList = (newList: T[]) => {
    setState(newList);
  };

  const hasItem = (item: T) => {
    return state.includes(item);
  };

  const addItem = (newItem: T) => {
    setState((prev) => {
      if (!prev.includes(newItem)) {
        const newState = [...prev];
        newState.push(newItem);

        return newState;
      }

      return prev;
    });
  };

  const removeItem = (itemToRemove: T) => {
    setState((prev) => {
      if (prev.includes(itemToRemove)) {
        const newState = prev.filter((i) => i !== itemToRemove);

        return newState;
      }

      return prev;
    });
  };

  const toggleItem = (item: T) => {
    setState((prev) => {
      if (prev.includes(item)) {
        const newState = prev.filter((i) => i !== item);
        return newState;
      } else {
        const newState = [...prev];
        newState.push(item);

        return newState;
      }
    });
  };

  return { state, setList, hasItem, addItem, removeItem, toggleItem };
};
